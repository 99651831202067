import * as React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import NotFound from "./pages/not-found";
import StartPage from "./pages/start-page";
import SurveyPage from "./pages/survey-page";
import FooterComponent from "./components/Footer";
import HeaderComponent from "./components/Header";
import AccessDeniedPage from "./pages/access-denied-page";
import { RouteNames } from "./types/routes-pathes";
import RelativeFormPage from "./pages/relative-form-page";
import SurveyRouteGuard from "./guards/SurveyGuard";
import FinalPage from "./pages/final-page";
import SuccessPageGuard from "./guards/SuccessPageGuard";
import PatientSearchPage from './pages/patient-search-page/index.';
import { useDispatch } from "react-redux"
import { fetchTranslations } from "./store/Translations/actions"
import { fetchAppVersions } from "./store/App/action"

const Routes: React.FC = () => {
    const dispatch = useDispatch()
    dispatch(fetchTranslations())
    dispatch(fetchAppVersions())

    return (
        <div className="flex flex-item full-height justify-center p-0">
        <main className="main-content-wrapper card-box">
                <HeaderComponent/>
                <Switch>
                    <Route exact path={RouteNames.Blank} component={StartPage}/>
                    <Route exact path={RouteNames.PatientSearch} component={PatientSearchPage}/>
                    <SurveyRouteGuard exact path={`${RouteNames.Survey}/:sectionId`} Component={SurveyPage}/>
                    <Route exact path={RouteNames.RelativeForm} component={RelativeFormPage}/>
                    <SuccessPageGuard exact path={RouteNames.FinalSuccess} Component={FinalPage}/>
                    <Route exact path={RouteNames.NotFound} component={NotFound}/>
                    <Route exact path={RouteNames.AccessDenied} component={AccessDeniedPage}/>
                    <Route path={RouteNames.Survey} exact={true}>
                        {
                            <Redirect to={`${RouteNames.Survey}/1`}/>
                        }
                    </Route>
                    <Route path='*' exact={true}>
                        {
                            <Redirect to={RouteNames.NotFound}/>
                        }
                    </Route>
                </Switch>
                <FooterComponent/>
        </main>
    </div>
    )
};

export default Routes;
