import { useSelector } from 'react-redux'
import { selectCurrentLanguage, selectTranslations } from "../store/Translations/translations"

// in the translations returned from apy we can have "common/no", but in hcp we store all translations in 1 object
function extractValue(inputString: string) {
    const parts = inputString.split('/')
    if (parts.length > 1) {
        return parts[1]
    } else {
        return inputString // Return the whole string if there's no '/'
    }
}

const useTranslate = () => {
    const translations = useSelector(selectTranslations)
    const langKey = useSelector(selectCurrentLanguage)

    return (key: string): string => {
        if (!key) {
            return key
        }
        let language = translations.find(lang => lang.key === langKey)
        if (!language) {
            language = translations.find(lang => lang.key === 'sv')
        }
        const defaultLanguage = translations.find(lang => lang.key === 'en')!
        const formattedKey = extractValue(key)
        const translation = language && language.translations[formattedKey]

        return translation ?? (defaultLanguage.translations[formattedKey] ?? formattedKey)
    }
}

export default useTranslate
