import React from "react";
import ProgressBarElementComponent from "../ProgressBarElement";

const SectionsProgressBarComponent: React.FC<any> = (props: any) => {
    const { sectionIds, currentSectionId } = props;
    return (
        <div className="flex justify-center pb-20">
            { sectionIds.map((id: string) => {
                const isCurrentSection = id === currentSectionId;
                return <ProgressBarElementComponent key={id} {...{ id, isCurrentSection }}/>
                })
            }
        </div>
    );
}

export default SectionsProgressBarComponent;
