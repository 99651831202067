import { TranslationsState, TranslationsActionType, TranslationsActionTypes, TranslationLanguage } from './types';
import {defaultLanguage} from "../../types/constants";

const initialState: TranslationsState = {
  languages: [],
  currentLanguage: defaultLanguage
};

export function TranslationReducer(
  state = initialState,
  action: TranslationsActionType
): TranslationsState {
  switch (action.type) {
    case TranslationsActionTypes.SWITCH_TRANSLATION:
      return {
        ...state,
        languages: state.languages.map((l: TranslationLanguage) => ({...l, inUse: l.key === action.language})),
        currentLanguage: action.language
      }
    case TranslationsActionTypes.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        languages: state.languages.map(
            (item) => item.key === action.payload.lang ? {...item, translations: Object.assign({}, item.translations, action.payload.translations)}
                : item
        )
      }
    default:
      return state;
  }
}
