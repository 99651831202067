import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { AuthActionTypes, LoginRequest, SearchPatientRequest } from './types'
import {
    loginSuccess,
    loginFail,
    setUnexpectedError,
} from './action'
import {
    markAsHttpPending,
    showErrorNotification,
    unmarkAsHttpPending
} from "../App/action"
import {
    mapTokenAuthErrorResponse
} from "../../utils/authMapper"
import {
    fetchQuestionsFail,
    fetchQuestionsSuccess,
    setSurveyQuestionsSet
} from "../Survey/action"
import { translate } from "../Translations/translations"
import { apiV2, attachTokenToRequests, SdkResponse, wrapResponse } from "../../sdk/api"
import { fetchTranslations } from "../Translations/actions"

function* emitUnexpectedError(): Generator {
    const message = translate('errors.unknownError')
    yield put(setUnexpectedError(message))
    yield put(showErrorNotification(message))
}

function* handleLoginRequest(opts: LoginRequest): Generator<any> {
    if (opts.data) {
        window.localStorage.setItem('token', opts.data)
        attachTokenToRequests()
    }

    yield put(markAsHttpPending())
    const apiCall = apiV2.oneTimeAuth.isTokenValid.bind(apiV2.oneTimeAuth)
    const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>
    if (!res.success) {
        yield put(loginFail())
        const message = mapTokenAuthErrorResponse(res.statusCode)
        yield put(showErrorNotification(message))
        yield put(unmarkAsHttpPending())
        yield emitUnexpectedError()
        return
    }
    yield put(loginSuccess())
    yield pullQuestionerSettings()

    yield put(unmarkAsHttpPending())
}

function* handlePatientSearchRequest(opts: SearchPatientRequest): Generator<any> {
    const headers: { [key: string]: string } = {}
    if (opts.data.personalNumber) {
        headers['personalNumber'] = opts.data.personalNumber
        const authApiCall = apiV2.oneTimeAuth.generateTestOneTimeUserJwtByPnr.bind(apiV2.oneTimeAuth)
        const authResult = (yield wrapResponse(authApiCall(opts.data.personalNumber))) as SdkResponse<typeof authApiCall>
        if (authResult.success) {
            window.localStorage.setItem('token', authResult.data)
            attachTokenToRequests()
        }
    }
    yield put(loginSuccess())

    yield put(markAsHttpPending())
    yield pullQuestionerSettings()
    yield put(unmarkAsHttpPending())
}

function* pullQuestionerSettings() {
    const questionerApiCall = apiV2.questioner.getSymptomSurveyQuestioner.bind(apiV2.oneTimeAuth)
    const questionerResult = (yield wrapResponse(questionerApiCall())) as SdkResponse<typeof questionerApiCall>
    if (questionerResult.success) {
        yield put(setSurveyQuestionsSet(questionerResult.data))
        yield put(fetchQuestionsSuccess())
    } else {
        yield emitUnexpectedError()
        yield put(fetchQuestionsFail())
    }
}

function* watchLoginRequest(): Generator {
    yield takeEvery(AuthActionTypes.POST_TOKEN_REQUEST, handleLoginRequest)
}

function* watchSearchPatientRequest(): Generator {
    yield takeEvery(AuthActionTypes.POST_PATIENT_REQUEST, handlePatientSearchRequest)
}

function* authSaga(): Generator {
    yield all([
        fork(watchLoginRequest),
        fork(watchSearchPatientRequest),
    ])
}

export default authSaga
