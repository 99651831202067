import React from "react"
import { useDispatch } from "react-redux"
import Select from "react-select"
import { answerRegularQuestion } from "../../store/Survey/action"
import { QuestionScreenDTO, SelectorInputMetadata } from "../../sdk"
import useTranslate from "../../hooks/useTranslate"
import { translate } from "../../store/Translations/translations"

const AnswerSelectComponent: React.FC<any> = ({ screen, answer }: {
    screen: QuestionScreenDTO
    answer: string
}) => {
    const translateLocalazy = useTranslate()
    const dispatch = useDispatch()

    const handleSelectChange = (selectedOption: any): void => {
        dispatch(answerRegularQuestion({
            id: screen.id!,
            value: selectedOption.value
        }))
    }
    const options = (screen.metadata.input.metadata as SelectorInputMetadata).values.map(e => ({
        value: e.value,
        label: translateLocalazy(e.label)
    }))
    const answerOption = options.find(e => e.value === answer)

    return (
        <Select
            className={'flex-item--fullwidth'}
            isSearchable={false}
            value={answerOption}
            placeholder={translate('form.placeholder.select')}
            onChange={handleSelectChange}
            // @ts-ignore
            options={options}
        />
    )
}

export default AnswerSelectComponent
