import React from "react"
import QuestionComponent from "../Question"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../store"
import AnswerSelectComponent from "../AnswerSelect"
import AnswerInputComponent from "../AnswerInput"
import AnswerTextareaComponent from "../AnswerTextarea"
import { FreeTextInputMetadata, FreeTextInputVisualisation, InputType, QuestionScreenDTO } from "../../sdk"

const SurveyHtmlFieldsetComponent: React.FC<any> = (props: {
    screen: QuestionScreenDTO,
    index: number,
    patientName: string
}) => {
    const { index, patientName, screen } = props
    const isTextareaInput = screen.metadata.input.kind === InputType.FREE_TEXT
        && (screen.metadata.input.metadata as FreeTextInputMetadata).visualisation === FreeTextInputVisualisation.BIG
    const vAlignClassName = !isTextareaInput ? 'align-center' : ''

    const normalizedQuestion = screen.metadata.title.replace('{patientName}', patientName)
    const answer = useSelector((state: ApplicationState) => state.surveyAnswers[screen.id!])
    const inputType = screen.metadata.input.kind
    const answerPayload = { screen, answer }
    return (
        <div className={`flex ${vAlignClassName} pv-20 questionset-container flex-item--wrap`}>
            <div className="flex flex-item item-md-6">
                <div className="flex-item--no-shrink">{index + 1}&nbsp;-&nbsp;</div>
                <QuestionComponent question={normalizedQuestion}/>
            </div>
            <div className="flex flex-item item-md-6 align-center input-holder pt-20 pt-0-md">
                {isTextareaInput && <AnswerTextareaComponent {...answerPayload} />}
                {inputType === InputType.FREE_TEXT && !isTextareaInput && <AnswerInputComponent {...answerPayload} />}
                {inputType === InputType.SELECTOR && <AnswerSelectComponent {...answerPayload} />}
            </div>
        </div>
    )
}

export default SurveyHtmlFieldsetComponent
