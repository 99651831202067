import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {switchTranslation} from "../../store/Translations/actions";
import {DropdownOption, LanguageItem} from "../../store/Translations/types";
import {getLanguages, translate} from "../../store/Translations/translations";
import Toggleable from "../Toggleable/Toggleable";
import SelectorBox from "../SelectorBox";
import DropdownMenu from "../DropdownMenu";
import {Languages} from "../../types/constants";

const LanguageSelectorComponent: React.FC<any> = () => {
    const dispatch = useDispatch();
    const availableLanguages = useSelector(getLanguages);
    const answerOptions = availableLanguages.map((item: LanguageItem): DropdownOption => {
        return {
            title: translate(item.key),
            propToPropagate: item.key,
            isSelected: item.inUse
        };
    });
    return (
        <>
            {
                <Toggleable>
                    {
                        (dropdownId: string, setDropdownId: (id: string) => void): React.ReactElement => {
                            const onClickHandler = (val: Languages): void => {
                                setDropdownId('');
                                dispatch(switchTranslation(val));
                            };
                            return (
                                <SelectorBox
                                    items={answerOptions}
                                    type="language"
                                    Elem={
                                        <DropdownMenu
                                            show={dropdownId === 'language'}
                                            onClick={onClickHandler}
                                            items={answerOptions}/>
                                    }
                                    dropdownId={dropdownId}
                                    onToggleMenu={setDropdownId}>
                                </SelectorBox>
                            )
                        }
                    }
                </Toggleable>
            }
        </>
    );
};

export default LanguageSelectorComponent;
