import { combineReducers, Reducer } from 'redux'
import {all, fork} from "redux-saga/effects";
import { AuthReducer } from "./Auth/reducer";
import { TranslationReducer } from './Translations/reducers';
import {RelativeFormReducer} from "./Relative/reducer";
import {SurveyAnswersReducer, SurveyDataReducer, SurveyReducer} from "./Survey/reducer";
import {AppState} from "./App/types";
import { TranslationsState } from './Translations/types';
import { AuthState } from "./Auth/types";
import {SurveyAnswersState, SurveyDataState, SurveyState} from "./Survey/types";
import {RelativeState} from "./Relative/types";
import authSaga from "./Auth/sagas";
import relativeSaga from "./Relative/sagas";
import surveySaga from "./Survey/sagas";
import {AppReducer} from "./App/reducer";
import translationsSaga from "./Translations/sagas"
import appSaga from "./App/sagas"

export interface ApplicationState {
  translations: TranslationsState;
  app: AppState;
  auth: AuthState;
  relative: RelativeState;
  survey: SurveyState;
  surveyData: SurveyDataState;
  surveyAnswers: SurveyAnswersState;
}

export const createRootReducer = (): Reducer<ApplicationState> => {
  return combineReducers({
    translations: TranslationReducer,
    app: AppReducer,
    auth: AuthReducer,
    relative: RelativeFormReducer,
    survey: SurveyReducer,
    surveyData: SurveyDataReducer,
    surveyAnswers: SurveyAnswersReducer
  });
};

export function* rootSaga(): Generator {
  yield all([
      fork(authSaga),
      fork(relativeSaga),
      fork(surveySaga),
      fork(translationsSaga),
      fork(appSaga)
  ]);
}
