import React from "react";
import {useSelector} from "react-redux";
import {getCurrentSurveyValidity} from "../../store/Survey/surveyStateSelectors";
import './ProgressBarElementComponent.scss';

const ProgressBarElementComponent: React.FC<any> = (props: any) => {
    const { id, isCurrentSection } = props;
    const currentValidity = useSelector(getCurrentSurveyValidity);
    let additionalClassName = '';
    if (isCurrentSection) {
        additionalClassName = 'is-current';
    } else {
        additionalClassName = currentValidity[id] ? 'is-passed' : '';
    }
    const className = `progress-item ${additionalClassName}`;
    return (
        <div className={className}></div>
    );
}

export default ProgressBarElementComponent;
