import {NotificationTypes} from "../../types/notification-types";
import { AppVersionDTO } from "../../sdk"

export enum AppActionTypes {
  SET_GLOBAL_ERROR = '@@app/SET_GLOBAL_ERROR',
  CLEAR_GLOBAL_ERROR = '@@app/CLEAR_GLOBAL_ERROR',
  SHOW_SUCCESS_NOTIFICATION = '@@app/SHOW_SUCCESS_NOTIFICATION',
  SHOW_ERROR_NOTIFICATION = '@@app/SHOW_ERROR_NOTIFICATION',
  RESET_NOTIFICATION = '@@app/RESET_NOTIFICATION',
  MARK_AS_HTTP_PENDING = '@@app/MARK_AS_HTTP_PENDING',
  UNMARK_AS_HTTP_PENDING = '@@app/UNMARK_AS_HTTP_PENDING',

  FETCH_APP_VERSIONS = '@app/FETCH_APP_VERSIONS',
  FETCH_APP_VERSIONS_SUCCESS = '@app/FETCH_APP_VERSIONS_SUCCESS'
}

export interface AppState {
  showNotification: boolean;
  errorMsg: string;
  notificationMessage: string;
  notificationType: NotificationTypes | null;
  isHTTPPending: boolean;
  appVersions?: AppVersionDTO
}

export interface SetGlobalError {
  type: AppActionTypes.SET_GLOBAL_ERROR;
  errorMsg: string;
}

export interface ClearGlobalError {
  type: AppActionTypes.CLEAR_GLOBAL_ERROR;
}

export interface ShowSuccessNotification {
  type: AppActionTypes.SHOW_SUCCESS_NOTIFICATION;
  message: string;
}

export interface ShowErrorNotification {
  type: AppActionTypes.SHOW_ERROR_NOTIFICATION;
  message: string;
}

export interface ResetNotification {
  type: AppActionTypes.RESET_NOTIFICATION;
}

export interface MarkAsHttpPending {
  type: AppActionTypes.MARK_AS_HTTP_PENDING;
}

export interface UnmarkAsHttpPending {
  type: AppActionTypes.UNMARK_AS_HTTP_PENDING;
}
export interface FetchAppVersions {
  type: AppActionTypes.FETCH_APP_VERSIONS;
}

export interface FetchAppVersionsSuccess {
  type: AppActionTypes.FETCH_APP_VERSIONS_SUCCESS
  data: AppVersionDTO
}

export type AppTypes = SetGlobalError |
    ClearGlobalError |
    ShowSuccessNotification |
    ShowErrorNotification |
    ResetNotification |
    MarkAsHttpPending |
    FetchAppVersions |
    FetchAppVersionsSuccess |
    UnmarkAsHttpPending;

