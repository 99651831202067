import {AppActionTypes, AppState, AppTypes} from "./types";
import {NotificationTypes} from "../../types/notification-types";

const initialState: AppState = {
  showNotification: false,
  errorMsg: '',
  notificationMessage: '',
  notificationType: null,
  isHTTPPending: false
}

export function AppReducer (
  state = initialState,
  action: AppTypes
): AppState {
  switch(action.type) {
    case AppActionTypes.SET_GLOBAL_ERROR:
      return {
        ...state,
        errorMsg: action.errorMsg,
      };
    case AppActionTypes.CLEAR_GLOBAL_ERROR:
      return {
        ...state,
        errorMsg: '',
      };
    case AppActionTypes.SHOW_SUCCESS_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        notificationType: NotificationTypes.Success,
        notificationMessage: action.message
      };
    case AppActionTypes.SHOW_ERROR_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        notificationType: NotificationTypes.Error,
        notificationMessage: action.message
      };
    case AppActionTypes.RESET_NOTIFICATION:
      return {
        ...state,
        showNotification: false,
        notificationType: null,
        notificationMessage: ''
      };
    case AppActionTypes.MARK_AS_HTTP_PENDING:
      return {
        ...state,
        isHTTPPending: true
      };
    case AppActionTypes.UNMARK_AS_HTTP_PENDING:
      return {
        ...state,
        isHTTPPending: false
      };
    case AppActionTypes.FETCH_APP_VERSIONS_SUCCESS:
      return {
        ...state,
        appVersions: action.data
      };
    default:
      return state
  }
}
