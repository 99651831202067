import React, { useState, ReactElement, useEffect } from 'react';

type DefaultProps = {
  id?: string;
  children: (dropdownId: string, setDropdownId: any) => ReactElement;
}

const Toogleable: React.FC<DefaultProps> = (props: DefaultProps) => {
  const { id } = props;
  const [dropdownId, setDropdownId] = useState(id || '');
  useEffect(() => {
    if (id) {
      setDropdownId(id)
    } else {
      setDropdownId('')
    }
  }, [])

  return props.children(dropdownId, setDropdownId);
};

export default Toogleable;
