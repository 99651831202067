import { TranslationsActionTypes, TranslationsActionType, FetchTranslationSuccessPayload } from './types'
import { Languages } from "../../types/constants"

export const fetchTranslations = (): TranslationsActionType => ({ type: TranslationsActionTypes.FETCH_TRANSLATIONS })
export const forceRefreshTranslations = (): TranslationsActionType => ({ type: TranslationsActionTypes.FORCE_REFRESH_TRANSLATIONS })

export const fetchTranslationsDone = (): TranslationsActionType => ({ type: TranslationsActionTypes.FETCH_TRANSLATIONS_DONE })
export const fetchTranslationsSuccess = (payload: FetchTranslationSuccessPayload): TranslationsActionType => ({
    type: TranslationsActionTypes.FETCH_TRANSLATIONS_SUCCESS,
    payload
})

export const switchTranslation = (language: Languages): TranslationsActionType => ({
    type: TranslationsActionTypes.SWITCH_TRANSLATION,
    language
})
