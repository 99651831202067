import React from 'react';
import {translate} from "../../store/Translations/translations";

const NotFoundPage: React.FC<any> = (): React.ReactElement => {
    return (
        <div className="flex justify-center align-center flex-column pv-40">
            <h1 className="h1 t_warning t_bold t_center">{translate('notFoundPage.header')}</h1>
            <p className="h3 pv-40">{translate('notFoundPage.helpMessage')}</p>
        </div>
    );
}

export default NotFoundPage;
