import { all, put, takeLatest } from 'redux-saga/effects'
import { AppActionTypes } from './types'

import { apiV2, SdkResponse, wrapResponse } from "../../sdk/api"
import { fetchAppVersionsSuccess } from "./action"

function* handleAppVersionsRequest(): Generator<any> {
    const apiCall = apiV2.projectSetting.getAppVersions.bind(apiV2.projectSetting)
    const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>
    if (res.success) {
        yield put(fetchAppVersionsSuccess(res.data))
    }
}

function* appSaga(): Generator {
    yield all([
        takeLatest(AppActionTypes.FETCH_APP_VERSIONS, handleAppVersionsRequest),
    ])
}

export default appSaga
