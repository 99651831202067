import React from 'react';
import { RouteComponentProps, Redirect, Route } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RouteNames } from "../../types/routes-pathes";

const SuccessPageRouteGuard: React.FC<any> = (props: any): React.ReactElement => {
    const { Component } = props;
    const isAnswersSavedSuccessfully = useSelector((state: any) => state.survey.isAnswersSavedSuccessfully);
    const render = (props: RouteComponentProps): React.ReactElement => {
        return isAnswersSavedSuccessfully ? <Component {...props} /> : <Redirect to={{ pathname: RouteNames.Blank }}/>;
    }
    return <Route {...props} render={render} />
}

export default SuccessPageRouteGuard;
