export enum AuthActionTypes {
  POST_TOKEN_REQUEST = '@@auth/POST_TOKEN_REQUEST',
  POST_PATIENT_REQUEST = '@@auth/POST_PATIENT_REQUEST',
  POST_LOGIN_SUCCESS = '@@auth/POST_LOGIN_SUCCESS',
  POST_SEARCH_SUCCESS = '@@auth/POST_SEARCH_SUCCESS',
  POST_LOGIN_FAILURE = '@@auth/POST_LOGIN_FAILURE',
  LOGOUT = '@@auth/LOGOUT',
  SET_ERROR_MSG = '@@auth/SET_ERROR_MSG',
  SET_UNEXPECTED_ERROR = '@@auth/SET_UNEXPECTED_ERROR',
  SEARCH_WITH_DEFAULT_VALUE = '@@auth/SEARCH_WITH_DEFAULT_VALUE'
}

export interface Patient {
  firstName: string;
  lastName: string;
  language: string;
}

export interface AuthState {
  isAuthenticated: boolean;
  authenticationFailed: boolean;
  patient: Patient | null;
  errorMsg: string;
  token: string | null;
  personalNumber: string | null;
  email: string | null;
  unexpectedError: boolean;
}

export interface CheckTokenPayload {
  token: string;
}

export interface PatientSearchPayload {
  personalNumber: string;
}

export interface LoginRequest {
  type: AuthActionTypes.POST_TOKEN_REQUEST;
  data: string;
}

export interface SearchPatientRequest {
  type: AuthActionTypes.POST_PATIENT_REQUEST;
  data: {
    personalNumber: string | null;
    email: string | null;
  };
}

export interface SearchPatientWithDefaultValueRequest {
  type: AuthActionTypes.SEARCH_WITH_DEFAULT_VALUE;
}

export interface LoginSuccess {
  type: AuthActionTypes.POST_LOGIN_SUCCESS;
}

export interface LoginFail {
  type: AuthActionTypes.POST_LOGIN_FAILURE;
}

export interface Logout {
  type: AuthActionTypes.LOGOUT;
}

export interface SetErrorMsg {
  type: typeof AuthActionTypes.SET_ERROR_MSG;
  errorMsg: string;
}

export interface SetUnexpectedError {
  type: AuthActionTypes.SET_UNEXPECTED_ERROR;
  errorMsg: string;
}

export type AuthTypes = LoginRequest |
    SearchPatientRequest |
    SearchPatientWithDefaultValueRequest |
    LoginSuccess |
    LoginFail |
    Logout |
    SetErrorMsg |
    SetUnexpectedError;

