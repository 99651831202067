/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManageSmsTemplateDto } from '../models/ManageSmsTemplateDto';
import type { SendSmsDto } from '../models/SendSmsDto';
import type { SendSsToRelativeDto } from '../models/SendSsToRelativeDto';
import type { SmsStatusChangeRequestDto } from '../models/SmsStatusChangeRequestDto';
import type { SmsTemplateDto } from '../models/SmsTemplateDto';
import type { SmsTemplateWithTranslationsDto } from '../models/SmsTemplateWithTranslationsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SmsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns SmsTemplateDto
     * @throws ApiError
     */
    public upsertTemplate(
        requestBody: ManageSmsTemplateDto,
    ): CancelablePromise<SmsTemplateDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sms/template',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SmsTemplateWithTranslationsDto
     * @throws ApiError
     */
    public getTemplates(): CancelablePromise<Array<SmsTemplateWithTranslationsDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sms/template',
        });
    }

    /**
     * @param templateId
     * @returns any
     * @throws ApiError
     */
    public removeTemplate(
        templateId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sms/template/{templateId}',
            path: {
                'templateId': templateId,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public sendSms(
        userId: string,
        requestBody: SendSmsDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sms/send-sms/to/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public sendSsToRelative(
        requestBody: SendSsToRelativeDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sms/send-ss/to-relative',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public trackSmsStatusChange(
        requestBody: SmsStatusChangeRequestDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sms/delivery-report',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
