import React from 'react';
import {Store} from 'redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux'
import './assets/scss/app.scss';
import {ApplicationState} from './store';
import Routes from './routes';
import NotificationComponent from './components/Notifications';

interface MainProps {
    store: Store<ApplicationState>;
}

// eslint-disable-next-line react/prop-types
const App: React.FC<MainProps> = ({ store }) => {
  return (
    <Provider store={store}>
      <Router>
        <div className="wrapper pv-20 ph-10">
          <Routes />
          <NotificationComponent />
        </div>
      </Router>
    </Provider >
  );
}

export default App;
