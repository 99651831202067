import React, {SyntheticEvent, useState} from "react";
import {translate} from "../../store/Translations/translations";

const PersonalNumberInputComponent: React.FC<any> = (props: any) => {
    const { parentChangeHandler, value } = props;
    const [ isOnceReplaced, setIsOnceReplaced ] = useState(false);

    const handleInputChange = (event: SyntheticEvent) => {
        parentChangeHandler(event);
    };

    const handleInputBlur = (event: SyntheticEvent) => {
        const target = event.target as any;
        let personalNumber = target.value;
        const normalizedPNR = personalNumber ? personalNumber.toString().replace(/-/g, '') : '';
        if (normalizedPNR.length === 10 && !isOnceReplaced) {
            personalNumber = `19${personalNumber}`;
            setIsOnceReplaced(true);
        }
        const payload: any = { ...event };
        payload.target.value = personalNumber;
        parentChangeHandler(payload);
    };

    return (
        <input value={value}
               name="personalNumberCrossEmail"
               type="text"
               onChange={handleInputChange}
               onBlur={handleInputBlur}
               placeholder={translate('form.placeholder.typeHere')}/>
    );
};

export default PersonalNumberInputComponent;
