import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { translate } from '../../store/Translations/translations';
import { resetNotification} from "../../store/App/action";

const NotificationComponent: React.FC = () => {
  const notificationMessage = useSelector((state: ApplicationState) => state.app.notificationMessage);
  const showNotification = useSelector((state: ApplicationState) => state.app.showNotification);
  const notificationType = useSelector((state: ApplicationState) => state.app.notificationType);
  const dispath = useDispatch();
  const closeNotification = (): void => {
      dispath(resetNotification());
  };
  const notificationClassName = `notification_wrapper--${notificationType}`;
  return (
    <div className="notification_box">
      {
          showNotification
        ? (<div className={`notification_wrapper ${notificationClassName}`}>
            <div className="notification_header">
              <button type="button" className="notification_close-btn" onClick={closeNotification}><CloseIcon /></button>
            </div>
            <div className="notification_body">{translate(notificationMessage)}</div>
          </div>)
        : ''
      }
      </div>
  );
}

export default NotificationComponent;
