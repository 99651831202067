import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {
    RelativeDataPayload,
    RelativeDataWithPnrAndEmailPayload
} from "../../store/Relative/types";
import {saveRelativeData} from "../../store/Relative/action";
import {relativeDataComplete} from "../../store/Relative/relativeData";
import RelativeFormComponent from "../../components/RelativeForm";
import {RouteNames} from "../../types/routes-pathes";
import LoaderComponent from "../../components/Loader";
import {searchPatientRequest} from "../../store/Auth/action";
import {isPatientAuthenticated} from "../../store/Auth/authSelectors";
import {isQuestionnaireReady} from "../../store/Survey/surveyStateSelectors";
import {ApplicationState} from "../../store";
import {resetNotification} from "../../store/App/action";
import {getCurrentLanguage} from "../../store/Translations/translations";

const RelativeFormPage = () => {
    useSelector(getCurrentLanguage);
    const isHTTPPending = useSelector((state: ApplicationState) => state.app.isHTTPPending);
    const isAuthenticated = useSelector(isPatientAuthenticated);
    const isQuestionnaireFetched = useSelector(isQuestionnaireReady);
    const isCanSaveRelativeData = useSelector(relativeDataComplete);
    const dispatch = useDispatch();
    const saveRelativeAccountInfo = (event: RelativeDataPayload): void => {
        dispatch(resetNotification());
        dispatch(saveRelativeData(event));
    };
    const searchPatientAndSaveRelativeAccountInfo = (event: RelativeDataWithPnrAndEmailPayload): void => {
        const { personalNumberCrossEmail, emailCrossPersonalNumber, ...rest } = event;
        const normalizedPNR = personalNumberCrossEmail ? personalNumberCrossEmail.toString().replace(/-/g, '') : null;
        dispatch(resetNotification());
        dispatch(saveRelativeData(rest));
        dispatch(searchPatientRequest(normalizedPNR, emailCrossPersonalNumber));
    };

    return (
        <div className="flex-item--grow">
            {
                isHTTPPending &&
                <LoaderComponent />
            }
            {
                !isHTTPPending && isAuthenticated && isCanSaveRelativeData && isQuestionnaireFetched &&
                <Redirect to={RouteNames.Survey}/>
            }
            {
                !isHTTPPending && isAuthenticated && !isCanSaveRelativeData && isQuestionnaireFetched &&
                <RelativeFormComponent
                    data={{
                        firstName: '',
                        lastName: '',
                        phone: ''
                    }}
                    onSubmit={saveRelativeAccountInfo}>
                </RelativeFormComponent>
            }
            {
                !isHTTPPending && !isAuthenticated &&
                <RelativeFormComponent
                    searchEnabled={true}
                    data={{
                        personalNumberCrossEmail: '',
                        emailCrossPersonalNumber: '',
                        firstName: '',
                        lastName: '',
                        phone: ''
                    }}
                    onSubmit={searchPatientAndSaveRelativeAccountInfo}>
                </RelativeFormComponent>
            }
            {
                !isHTTPPending && isAuthenticated && !isQuestionnaireFetched && !isCanSaveRelativeData &&
                <RelativeFormComponent
                    searchEnabled={true}
                    data={{
                        personalNumber: '',
                        firstName: '',
                        lastName: '',
                        phone: ''
                    }}
                    onSubmit={searchPatientAndSaveRelativeAccountInfo}>
                </RelativeFormComponent>
            }
        </div>
    )
}

export default RelativeFormPage;
