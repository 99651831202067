import {AppActionTypes, AppTypes} from "./types";
import { AppVersionDTO } from "../../sdk"

export const setGlobalError = (errorMsg: string): AppTypes => ({type: AppActionTypes.SET_GLOBAL_ERROR, errorMsg});
export const clearGlobalError = (): AppTypes => ({type: AppActionTypes.CLEAR_GLOBAL_ERROR});
export const showSuccessNotification = (message: string): AppTypes => ({type: AppActionTypes.SHOW_SUCCESS_NOTIFICATION, message});
export const showErrorNotification = (message: string): AppTypes => ({type: AppActionTypes.SHOW_ERROR_NOTIFICATION, message});
export const resetNotification = (): AppTypes => ({type: AppActionTypes.RESET_NOTIFICATION});
export const markAsHttpPending = (): AppTypes => ({type: AppActionTypes.MARK_AS_HTTP_PENDING});
export const unmarkAsHttpPending = (): AppTypes => ({type: AppActionTypes.UNMARK_AS_HTTP_PENDING});
export const fetchAppVersions = (): AppTypes => ({type: AppActionTypes.FETCH_APP_VERSIONS});
export const fetchAppVersionsSuccess = (appVersions: AppVersionDTO): AppTypes => ({type: AppActionTypes.FETCH_APP_VERSIONS_SUCCESS, data: appVersions});
