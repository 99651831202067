/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Mark that current user allowed us to export his data
     * @returns any
     * @throws ApiError
     */
    public acceptDataExport(): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/accept-data-export',
        });
    }

    /**
     * @returns string
     * @throws ApiError
     */
    public getAllPatientFiles(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/get-all-patient-files',
        });
    }

}
