import { all, put, takeLatest } from 'redux-saga/effects'
import { TranslationsActionTypes } from './types'
import { fetchTranslationsDone, fetchTranslationsSuccess } from "./actions"
import { TranslationApp } from "../../sdk"
import { apiV2, SdkResponse, wrapResponse } from "../../sdk/api"

function* handleFetchTranslations(): Generator {
    const apiCall = apiV2.translations.getBatch.bind(apiV2.translations)
    const res = (yield wrapResponse(apiCall([TranslationApp.GERAS_COMMON, TranslationApp.GERAS_SYMPTOM_SURVEY]))) as SdkResponse<typeof apiCall>

    if (res.success) {
        for (const [, trans] of Object.entries(res.data)) {
            // @ts-ignore
            for (const [key, value] of Object.entries(trans)) {
                yield put(fetchTranslationsSuccess({ lang: key, translations: value as any }))
            }
        }
    }

    yield put(fetchTranslationsDone())
}

function* handleForceRefreshTranslations(): Generator {
    const apiCall = apiV2.translations.forceRefresh.bind(apiV2.translations)
    yield wrapResponse(apiCall())
    yield handleFetchTranslations()
}

function* translationsSaga(): Generator {
    yield all([
        takeLatest(TranslationsActionTypes.FETCH_TRANSLATIONS, handleFetchTranslations),
        takeLatest(TranslationsActionTypes.FORCE_REFRESH_TRANSLATIONS, handleForceRefreshTranslations),
    ])
}

export default translationsSaga
