import React from "react"
import './RegularAnswerComponent.css'
import useTranslate from "../../hooks/useTranslate"

const RegularAnswerComponent: React.FC<any> = ({ label, isSelected, onClick }: {
    label: string,
    isSelected: boolean,
    onClick: () => void
}) => {
    const translateLocalazy = useTranslate()
    const selectedClassName = isSelected ? 'selected' : ''
    return (
        <div className="flex align-center justify-center-md flex-item--wrap-md flex-column-md answer-wrapper"
             onClick={onClick}>
            <div className={`radio-icon flex-item--no-shrink ${selectedClassName}`}></div>
            <p className="pb-10 pt-10 pt-0-xl t_center-md">{translateLocalazy(label)}</p>
        </div>
    )
}

export default RegularAnswerComponent
