import React from 'react';
import {translate} from "../../store/Translations/translations";

const FinalPage: React.FC<any> = (): React.ReactElement => {
    return (
        <div className="flex justify-center flex-column pv-40 flex-item--grow">
            <h1 className="h5 t_normal t_center">{translate('successPage.heading')}</h1>
            <p className="h5 t_center pv-20">{translate('successPage.thanksMessage')}</p>
        </div>
    );
}

export default FinalPage;
