import React, { useEffect } from 'react';
import {translate} from "../../store/Translations/translations";
import useFormStateHandler from "../../hooks/formStateHandler";
import {RelativeDataWithPnrAndEmailPayload} from "../../store/Relative/types";
import {useSelector} from "react-redux";
import {getPatientName} from "../../store/Auth/authSelectors";
import PersonalNumberInputComponent from '../../components/PersonalNumberInput'

interface RelativeFormPropsData {
    firstName: string;
    lastName: string;
    phone?: string;
    personalNumberCrossEmail?: string;
    emailCrossPersonalNumber?: string;
    personalNumber?: string;
}

type RelativeFormProps = {
    data: RelativeFormPropsData;
    onSubmit: (event: RelativeDataWithPnrAndEmailPayload) => void;
    searchEnabled?: boolean;
}

const RelativeFormComponent: React.FC<RelativeFormProps> = (props: RelativeFormProps) => {
    const {onSubmit, data, searchEnabled } = props;
    const {inputs, errors, handleSubmit, handleInputChange, handleForceUpdate} = useFormStateHandler(onSubmit, data);
    const patientName = useSelector(getPatientName);
    useEffect(() => {
        const _data = Object.keys(data)
            .map((key: string) => data[key as keyof RelativeFormPropsData] ? {[key]: data[key as keyof RelativeFormPropsData]} : {})
            .reduce((acc, obj) => ({...acc, ...obj}), {})
        handleForceUpdate(_data);
    }, [data]);
    const PNRprops = {
        value: inputs.personalNumberCrossEmail,
        parentChangeHandler: handleInputChange
    };
    return (
        <div className="pt-20 ph-sm-20">
            { searchEnabled &&
                <div className="flex-item item-sm-10 item-lg-6 mh-auto t_center">
                    <h1 className="h3 t_center">{translate('relative.relativeForm.headingSearch')}</h1>
                </div>
            }
            { !searchEnabled &&
                <div className="flex-item item-sm-10 item-lg-6 mh-auto t_center">
                    <h1 className="h3 pb-20 t_center">{translate('relative.relativeForm.headingIfAuth')}&nbsp;{patientName}</h1>
                    <p className="m-0 pb-20">
                        <span>{translate('relative.relativeForm.headingIfAuthDescriptionMain1')}</span>
                        <span>&nbsp;{patientName}&nbsp;</span>
                        <span>{translate('relative.relativeForm.headingIfAuthDescriptionMain2')}</span>
                    </p>
                    <p className="m-0">{translate('relative.relativeForm.headingIfAuthDescriptionAdditional')}</p>
                </div>
            }
            <div className="flex-container">
                <div className="flex-item item-sm-9 item-md-6 item-lg-5 item-xl-4 mh-auto pt-40 ph-20">

                    { searchEnabled &&
                        <div className={`input-holder pb-20 ${(errors.includes('pnrIsRequired') || errors.includes('invalidPnr')) && 'field_error'}`}>
                            <label className="pb-10">{translate('personalNumber')}</label>
                            <PersonalNumberInputComponent { ...PNRprops } />
                            { errors.includes('pnrIsRequired') &&
                                <p className="h7 pt-10 t_error">{translate('form.error.mustNotEmpty')}</p>
                            }
                            { errors.includes('invalidPnr') &&
                                <p className="h7 pt-10 t_error">{translate('form.error.persNumberFormat')}</p>
                            }
                            { !errors.includes('pnrIsRequired') && !errors.includes('invalidPnr') &&
                                <p className="h7 t_right pt-10">{translate('patientSearch.hint.persNumberFormat')}</p>
                            }
                        </div>
                    }

                    <div className={`input-holder pb-20 ${errors.includes('firstName') && 'field_error'}`}>
                        <label className="pb-10">{translate('firstName')}</label>
                        <input value={inputs.firstName}
                               name="firstName"
                               type="text"
                               onChange={handleInputChange}
                               placeholder={translate('form.placeholder.typeHere')}/>
                        { errors.includes('firstName') &&
                            <p className="h7 pt-10 t_error">{translate('form.error.mustNotEmpty')}</p>
                        }
                        { !errors.includes('firstName') &&
                                <p className="h7 t_right pt-10">{translate('form.hint.mustNotEmpty')}</p>
                        }
                    </div>

                    <div className={`input-holder pb-20 ${errors.includes('lastName') && 'field_error'}`}>
                        <label className="pb-10">{translate('lastName')}</label>
                        <input value={inputs.lastName}
                               name="lastName"
                               type="text"
                               onChange={handleInputChange}
                               placeholder={translate('form.placeholder.typeHere')}/>
                        { errors.includes('lastName') &&
                            <p className="h7 pt-10 t_error">{translate('form.error.mustNotEmpty')}</p>
                        }
                        { !errors.includes('lastName') &&
                            <p className="h7 t_right pt-10">{translate('form.hint.mustNotEmpty')}</p>
                        }
                    </div>

                    <div className={`input-holder pb-20`}>
                        <label className="pb-10">{translate('phone')}</label>
                        <input value={inputs.phone}
                               name="phone"
                               type="tel"
                               onChange={handleInputChange}
                               placeholder={translate('form.placeholder.typeHere')}/>
                        { !errors.includes('phone') &&
                            <p className="h7 t_right pt-10">{translate('form.hint.fieldIsOptional')}</p>
                        }
                    </div>
                </div>
            </div>
            <div className="btns-holder t_center pt-20">
                <button onClick={handleSubmit}
                        className="t_uppercase g_btn g_btn--primary"
                        type="button">{translate('relative.relativeForm.saveButton')}</button>
            </div>
        </div>
    );
};

export default RelativeFormComponent;
