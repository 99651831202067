import React, {useRef, useLayoutEffect, useCallback} from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/icons/en-flag.svg';
interface DropdownItem {
  title: string;
  isSelected: boolean;
  propToPropagate?: string;
}

interface SelectorBoxProps {
  items: DropdownItem[];
  type: string;
  dropdownId: string;
  Elem: React.ReactElement;
  onSelect?: (val: any) => void;
  onToggleMenu: (id: string) => void;
}

const SelectorBox: React.FC<SelectorBoxProps> = (props: SelectorBoxProps) => {
  const { dropdownId, items, type, Elem, onToggleMenu} = props;
  const node: any = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((e: Event): void => {
    if (!node.current.contains(e.target)) {
      onToggleMenu('')
      return;
    }
  }, []);

  useLayoutEffect(() => {
    if (dropdownId && (dropdownId === type)) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return (): void => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownId]);

  return (
    <div ref={node} className="selector-box">
      <div className="selector_selected-block" onClick={(): void => onToggleMenu(type === dropdownId ? '' : type)}>
        <div className="part ellipsis"><span>{items.filter((i: DropdownItem) => i.isSelected)[0].title}</span></div>
        <div className="part"><ArrowDownIcon /></div>
      </div>
      { (type === dropdownId) && Elem }
    </div>
  );
}

export default SelectorBox;
