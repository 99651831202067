import {ApplicationState} from "../index";
import {AuthState} from "./types";
import {createSelector} from "reselect";
import {translate} from "../Translations/translations";

const selectAuth = (state: ApplicationState): AuthState => state.auth;

const evaluateAuthStatus = (state: AuthState): boolean => {
    return state.isAuthenticated && !state.authenticationFailed && !state.unexpectedError;
};

const getAuthCreds = (state: AuthState) => {
    const { token, personalNumber, email } = state;
    if (token) {
        return {
            token
        };
    }
    if (personalNumber) {
        return {
            personalNumber
        };
    }
    if (email) {
        return {
            email
        };
    }
};

export const getPatientName = createSelector(
    selectAuth,
    (state: AuthState) => {
        const { patient } = state;
        if (!patient) {
            return translate('thePatient');
        }
        const { firstName, lastName } = patient;
        const fullName = `${firstName} ${lastName}`;
        return fullName.trim() ? fullName : translate('thePatient');
    }
);

export const isPatientAuthenticated = createSelector(
    selectAuth,
    evaluateAuthStatus
);

export const getAuthCredsData = createSelector(
    selectAuth,
    getAuthCreds
);
