export enum RelativeActionTypes {
    POST_RELATIVE_DATA = '@@relative/POST_RELATIVE_DATA',
    SAVE_RELATIVE_DATA = '@@relative/SAVE_RELATIVE_DATA',
    CLEAR_RELATIVE_DATA = '@@relative/CLEAR_RELATIVE_DATA',
}

export interface RelativeDto {
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface RelativeState {
    firstName: string;
    lastName: string;
    phone: string;
}

export interface RelativeDataPayload {
    firstName: string;
    lastName: string;
    phone: string;
}

export interface RelativeDataWithPnrAndEmailPayload extends RelativeDataPayload {
    personalNumberCrossEmail: string | null;
    emailCrossPersonalNumber: string | null;
}

export interface RelativePost {
    type: RelativeActionTypes.POST_RELATIVE_DATA;
    data: RelativeDataPayload;
}

export interface RelativeSave {
    type: RelativeActionTypes.SAVE_RELATIVE_DATA;
    data: RelativeDataPayload;
}

export interface RelativeClear {
    type: RelativeActionTypes.CLEAR_RELATIVE_DATA;
}

export type RelativeTypes = RelativePost | RelativeSave | RelativeClear;
