import { Store, createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga';
import { ApplicationState, createRootReducer, rootSaga } from './store'
import localStorageEnchancer from './middlewares/localStorage';
import en from './i18n/en';
import sv from './i18n/sv';
import {defaultLanguage, Languages, UtilTypes} from "./types/constants";

const _getLanguageFromLocalStorage = (): Languages => window.localStorage.getItem(UtilTypes.Language) as Languages;

const _isLanguageStored = (key: Languages): boolean => Boolean(_getLanguageFromLocalStorage() === key);

const _setLang = <T extends {inUse: boolean}>(languages: T[]): T[] => languages.filter((l: T) => !l.inUse).length === languages.length
  ? languages.map((l: T, i: number) => ({...l, inUse: i === 0}))
  : languages;
  
const rootState: any = {
  translations: {
      languages: _setLang(
          [
            {
                key: Languages.En,
                translations: en,
                inUse: _isLanguageStored(Languages.En)
            },
            {
                key: Languages.Sv,
                translations: sv,
                inUse: _isLanguageStored(Languages.Sv)
            },
          ]
      ),
      currentLanguage: _getLanguageFromLocalStorage() || defaultLanguage
  }
};

const sagaMiddleware = createSagaMiddleware();

function configureStore(): Store<ApplicationState> {
    if (process.env.NODE_ENV !== 'production') {
        const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
        const store = createStore(
            createRootReducer(),
            rootState,
            composeEnhancers(applyMiddleware(localStorageEnchancer, sagaMiddleware))
        );
        return store;
    } else {
        const store = createStore(
            createRootReducer(),
            rootState,
            applyMiddleware(localStorageEnchancer, sagaMiddleware)
        );
        return store;
    }
}

export const store = configureStore();

sagaMiddleware.run(rootSaga);

export default store;
