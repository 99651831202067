/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankIdAttemptDto } from '../models/BankIdAttemptDto';
import type { LoginCodeBodyDto } from '../models/LoginCodeBodyDto';
import type { LoginWithCodeResponseDto } from '../models/LoginWithCodeResponseDto';
import type { LoginWithPasswordBodyDto } from '../models/LoginWithPasswordBodyDto';
import type { LoginWithPasswordResponseDto } from '../models/LoginWithPasswordResponseDto';
import type { RefreshTokensDto } from '../models/RefreshTokensDto';
import type { SessionDto } from '../models/SessionDto';
import type { StartBankIdAuthDto } from '../models/StartBankIdAuthDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Start auth attempt with BankId
     * @param requestBody
     * @returns BankIdAttemptDto
     * @throws ApiError
     */
    public startBankIdAuth(
        requestBody: StartBankIdAuthDto,
    ): CancelablePromise<BankIdAttemptDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/start-bankid',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check BankId Status
     * @param orderRef
     * @returns any
     * @throws ApiError
     */
    public bankIdStatus(
        orderRef: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/bankid-status',
            query: {
                'orderRef': orderRef,
            },
        });
    }

    /**
     * Login with login code
     * @param requestBody
     * @returns LoginWithCodeResponseDto
     * @throws ApiError
     */
    public loginWithCode(
        requestBody: LoginCodeBodyDto,
    ): CancelablePromise<LoginWithCodeResponseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/login-code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Login with password
     * @param requestBody
     * @returns LoginWithPasswordResponseDto
     * @throws ApiError
     */
    public loginWithPassword(
        requestBody: LoginWithPasswordBodyDto,
    ): CancelablePromise<LoginWithPasswordResponseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SessionDto
     * @throws ApiError
     */
    public getSession(): CancelablePromise<SessionDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/session',
        });
    }

    /**
     * Check Access Token Strategy
     * @returns string
     * @throws ApiError
     */
    public checkAccessStrategy(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/check-access-strategy',
        });
    }

    /**
     * Generate jwt for doctor/patient for test purposes
     * @param userId
     * @returns string
     * @throws ApiError
     */
    public generateTestJwt(
        userId: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/generate-test-user-jwt/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * Generate jwt for one-time-user for test purposes
     * @param token
     * @returns string
     * @throws ApiError
     */
    public generateTestOneTimeUserJwt(
        token: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/generate-test-one-time-user-jwt/{token}',
            path: {
                'token': token,
            },
        });
    }

    /**
     * Refresh tokens
     * @returns RefreshTokensDto
     * @throws ApiError
     */
    public checkRefreshStrategy(): CancelablePromise<RefreshTokensDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/refresh-token',
        });
    }

}
