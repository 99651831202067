import React from 'react'
import FactoryLogo from '../../assets/img/branding/Factory.png'
import CElogo from '../../assets/img/branding/CE.png'
import MDlogo from '../../assets/img/branding/MD.png'
import UDIlogo from '../../assets/img/branding/UDI.png'
import REFlogo from '../../assets/img/branding/REF.png'
import symtomenkat from '../../assets/img/symtomenkat.png'
import useTranslate from "../../hooks/useTranslate"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../store"

const FooterComponent = () => {
    const translateLocalazy = useTranslate()
    const version = useSelector((state: ApplicationState) => state.app.appVersions)

    return (
        <footer className="footer pt-40">
            <div className="flex flex--wrap ph-20">
                <div className="flex w-100 justify-between flex--wrap">
                    <div className="footer-item t_left-sm flex flex-column align-center ju">
                        <div className="footer-legal-mark flex flex-column align-start">
                            <p>Neurokognitiv Symtomenkät&reg;</p>
                            <p>CIMP-QUEST&trade;</p>
                            <p>&reg;Ragnar Åstrand</p>
                            <p>
                                <a href="https://www.symtomenkaten.se/pdfs/03b2.%20Symtomenk%C3%A4ten%20Referenser.pdf"
                                   rel="noopener noreferrer"
                                   target="_blank">Referenser – pdf</a>
                            </p>
                            <img src={symtomenkat} height={30}/>
                        </div>
                    </div>
                    <div className="footer-item t_left-sm pt-20-sm flex flex-column align-center">
                        <div className="footer-legal-mark flex align-top">
                            <img src={CElogo} height={30}/>
                        </div>
                        <div className="footer-legal-mark flex align-top">
                            <img src={MDlogo} height={30}/>
                            <span className="pl-10">{translateLocalazy('branding.md')}</span>
                        </div>
                        <div className="footer-legal-mark flex align-top">
                            <img src={UDIlogo} height={30}/>
                            <span className="pl-10">{translateLocalazy('branding.udi')}</span>
                        </div>
                    </div>
                    <div className="footer-item t_left-sm pt-20-sm flex justify-center">
                        <div className="footer-legal-mark flex align-top">
                            <img src={REFlogo} height={30}/>
                            <div className="pl-10">
                                <p>{`${translateLocalazy('versioning.hcp')}: v. ${version?.hcpPortal || '--'}`}</p>
                                <p>{`${translateLocalazy('versioning.pp')}: v. ${version?.patientPortal || '--'}`}</p>
                                <p>{`${translateLocalazy('versioning.ios')}: v. ${version?.ios || '--'}`}</p>
                                <p>{`${translateLocalazy('versioning.android')}: v. ${version?.android || '--'}`}</p>
                                <p>{`${translateLocalazy('versioning.ss')}: v. ${version?.symptomSurvey || '--'}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className="footer-item t_left-sm pt-20-sm flex justify-center">
                        <div className="footer-legal-mark flex align-top">
                            <img src={FactoryLogo} height={40}/>
                            <span className="pl-10" dangerouslySetInnerHTML={{__html: translateLocalazy('branding.corp').replaceAll('\n', '<br/>')}}></span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent
