import {
  AuthTypes,
  AuthActionTypes,
} from './types';

export const loginRequest = (token: string): AuthTypes => ({type: AuthActionTypes.POST_TOKEN_REQUEST, data: token});
export const searchPatientRequest = (personalNumber: string | null, email: string | null): AuthTypes => ({type: AuthActionTypes.POST_PATIENT_REQUEST, data: { personalNumber, email } });
export const loginSuccess = (): AuthTypes => ({type: AuthActionTypes.POST_LOGIN_SUCCESS});
export const loginFail = (): AuthTypes => ({type: AuthActionTypes.POST_LOGIN_FAILURE});
export const logout = (): AuthTypes => ({type: AuthActionTypes.LOGOUT});
export const setErrorMsg = (errorMsg: string): AuthTypes => ({type: AuthActionTypes.SET_ERROR_MSG, errorMsg});
export const setUnexpectedError = (errorMsg: string): AuthTypes => ({type: AuthActionTypes.SET_UNEXPECTED_ERROR, errorMsg});
export const searchPatientWithDefaultValue = (): AuthTypes => ({ type: AuthActionTypes.SEARCH_WITH_DEFAULT_VALUE });
