/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StartBankIdAuthDto = {
    referrer: StartBankIdAuthDto.referrer;
};

export namespace StartBankIdAuthDto {

    export enum referrer {
        MOBILE_APP = 'mobileApp',
        HCP = 'hcp',
        PATIENT_PORTAL = 'patientPortal',
    }


}

