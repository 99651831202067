import React from 'react';
import { RouteComponentProps, Redirect, Route } from 'react-router-dom';
import { useSelector } from "react-redux";
import {RouteNames} from "../../types/routes-pathes";
import {relativeDataComplete} from "../../store/Relative/relativeData";
import {
    getCurrentSurveyValidity,
    isQuestionnaireReady,
    sectionsSelector
} from "../../store/Survey/surveyStateSelectors"
import {isPatientAuthenticated} from "../../store/Auth/authSelectors";

const SurveyRouteGuard: React.FC<any> = (props: any): React.ReactElement => {
    const { Component, computedMatch } = props;
    const sectionId = computedMatch.params['sectionId'];
    const isAuthenticated = useSelector(isPatientAuthenticated);
    const isQuestionnaireFetched = useSelector(isQuestionnaireReady);
    const isRelativeDataComplete = useSelector(relativeDataComplete);
    const validity = useSelector(getCurrentSurveyValidity);
    const isAnswersSavedSuccessfully = useSelector((state: any) => state.survey.isAnswersSavedSuccessfully);
    const sections = useSelector(sectionsSelector)

    const render = (props: RouteComponentProps): React.ReactElement => {
        if (isAnswersSavedSuccessfully) {
            return <Redirect to={RouteNames.FinalSuccess}/>;
        }
        if (!isAuthenticated || !isQuestionnaireFetched) {
            return <Redirect to={{ pathname: RouteNames.Blank }}/>;
        }
        if (isAuthenticated && !isRelativeDataComplete) {
            return <Redirect to={{ pathname: RouteNames.RelativeForm }}/>;
        }
        if (isAuthenticated && isRelativeDataComplete && isQuestionnaireFetched) {
            if (!/^[1-6]$/.test(sectionId)) {
                return <Redirect to={{ pathname: RouteNames.NotFound }}/>;
            }
            if (sectionId === '1') {
                return <Component {...props} />;
            }
            const parsedId = parseInt(sectionId, 10);
            for (let i = 1; i < parsedId; i++) {
                const section = sections[i - 1]
                if (!validity[section.slug]) {
                    return <Redirect to={{ pathname: `${RouteNames.Survey}/${i}` }}/>;
                }
            }
            return <Component {...props} />;
        } else {
            return <Redirect to={{ pathname: RouteNames.Blank }}/>;
        }
    }
    return <Route {...props} render={render} />
}

export default SurveyRouteGuard;
