import React from "react"
import QuestionComponent from "../Question"
import RegularAnswerComponent from "../RegularAnswer"
import { QuestionScreenDTO, RadioGroupInputDTO } from "../../sdk"
import { useDispatch, useSelector } from "react-redux"
import { answerRegularQuestion } from "../../store/Survey/action"
import { getAnswerValue } from "../../store/Survey/surveyStateSelectors"

const SurveyRegularFieldsetComponent = ({ screen, index }: {
    screen: QuestionScreenDTO
    index: number
}) => {
    const dispatch = useDispatch()

    const input = screen.metadata.input as RadioGroupInputDTO
    const selectedValue = useSelector(getAnswerValue(screen.id!))
    return (
        <div className="flex align-center pv-20 questionset-container flex-item--wrap">
            <div className="flex flex-item item-xl-6">
                <div className="flex-item--no-shrink">{index}&nbsp;-&nbsp;</div>
                <QuestionComponent question={screen.metadata.title}/>
            </div>
            <div className="flex flex-item flex-column flex-row-md item-xl-6 align-end-md pt-10 pt-0-xl">
                {input.metadata.values.map((item) =>
                    (<div key={item.value} className="flex-item item-md-3">
                        <RegularAnswerComponent
                            label={item.label}
                            isSelected={selectedValue === item.value}
                            onClick={() => dispatch(answerRegularQuestion({
                                value: item.value,
                                id: screen.id!
                            }))
                            }/>
                    </div>))
                }
            </div>
        </div>
    )
}

export default SurveyRegularFieldsetComponent
