import React from "react"
import useTranslate from "../../hooks/useTranslate"

const QuestionComponent: React.FC<any> = (props: { question: string }) => {
    const { question } = props
    const translateLocalazy = useTranslate()

    return (
        <div dangerouslySetInnerHTML={{ __html: translateLocalazy(question) }}></div>
    )
}

export default QuestionComponent
