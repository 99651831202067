import {all, fork, put, takeEvery} from "redux-saga/effects";
import {RelativeActionTypes, RelativePost} from "./types";
import {saveRelativeData} from "./action";

function* handleRelativePost(opts: RelativePost): Generator<any> {
    yield put(saveRelativeData(opts.data));
}

function* watchRelativePost(): Generator {
    yield takeEvery(RelativeActionTypes.POST_RELATIVE_DATA, handleRelativePost);
}

function* relativeSaga(): Generator {
    yield all([
        fork(watchRelativePost)
    ]);
}

export default relativeSaga;
