import { ApplicationState } from "../index"
import { SurveyAnswersState, SurveyDataState, SurveyState } from "./types"
import { createSelector } from "reselect"
import { AnswerDTO, QuestionScreenDTO, SectionDTO } from "../../sdk"

interface SectionValidity {
    [key: string]: boolean;
}

const selectSections = (state: ApplicationState): SurveyDataState => state.surveyData

const selectStatus = (state: ApplicationState): SurveyState => state.survey

const selectAnswers = (state: ApplicationState): SurveyAnswersState => state.surveyAnswers

export const sectionsSelector = createSelector(
    selectSections,
    (state) => {
        return state.sections
    }
)
export const questionerIdSelector = createSelector(
    selectSections,
    (state) => {
        return state.questionerId
    }
)

const getValidity = (sections: SectionDTO[], answers: SurveyAnswersState): SectionValidity => {
    return sections.reduce((prev: SectionValidity, section): SectionValidity => {
        prev[section.slug] = section.screens.reduce((prev, question): boolean => {
            if (!prev) {
                return prev
            }
            if ((question.screen as QuestionScreenDTO).metadata.skippable) {
                return prev
            }
            const answerValue = answers[question.screen.id!]
            if (!answerValue) {
                return false
            }
            return !!(answerValue.trim())
        }, true)
        return prev
    }, {})
}

const getTotalValidity = (validity: SectionValidity): boolean => {
    return Object.keys(validity).reduce((prev: boolean, key: string): boolean => {
        return prev && validity[key]
    }, true)
}

const fetchedStatusSelector = createSelector(
    selectStatus,
    (state: SurveyState): boolean => {
        return state.isQuestionnaireFetched && !state.isFailedFetchingData
    }
)

const questionnaireStatusSelector = createSelector(
    sectionsSelector,
    (sections): boolean => {
        return !!sections?.length
    }
)

const getQuestionnaireStatus = (isFetched: boolean, isSectionsReady: boolean): boolean => {
    return isFetched && isSectionsReady
}

const mapAnswersToServer = (sections: Array<SectionDTO>, answers: SurveyAnswersState): AnswerDTO[] => {
    const answersDto: Array<AnswerDTO> = []
    sections.forEach((section) => {
        section.screens.forEach((question) => {
            if (answers[question.screen.id!]) {
                answersDto.push({
                    screen: question.screen.id!,
                    // @ts-ignore
                    value: answers[question.screen.id!]
                })
            }
        })
    })
    return answersDto
}

export const isQuestionnaireReady = createSelector(
    fetchedStatusSelector,
    questionnaireStatusSelector,
    getQuestionnaireStatus
)

export const getCurrentSurveyValidity = createSelector(
    sectionsSelector,
    selectAnswers,
    getValidity
)

export const getEntireSurveyValidity = createSelector(
    getCurrentSurveyValidity,
    getTotalValidity
)

export const getAnswersState = createSelector(
    sectionsSelector,
    selectAnswers,
    mapAnswersToServer
)

export const getAnswerValue = (questionId: string) => {
    return createSelector(
        selectAnswers,
        (answers: SurveyAnswersState) => {
            return answers[questionId] || null
        }
    )
}
