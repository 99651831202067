import React from "react";
import {translate} from "../../store/Translations/translations";
import {RouteNames} from "../../types/routes-pathes";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {resetNotification} from "../../store/App/action";

const ButtonToSearchPatientComponent: React.FC<any> = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const goToSearchPage = (): void => {
        dispatch(resetNotification());
        history.push(RouteNames.RelativeForm);
    };
    return (
        <div className="btns-holder">
            <button className="t_uppercase g_btn g_btn--primary"
                    onClick={goToSearchPage}
                    type="button">
                {translate('startPage.button.goToSearch')}
            </button>
        </div>
    );
};

export default ButtonToSearchPatientComponent;
