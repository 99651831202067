const trans = {
  'accessDeniedPage.header': 'Oops, something went wrong',
  'accessDeniedPage.helpMessage': 'Please make sure the link is correct',
  'en': 'English',
  'errors.generalAuthPNRError': 'Provided personal number cannot be used for authentication',
  'errors.generalAuthTokenError': 'Provided link cannot be used for authentication',
  'errors.invalidOrNoToken': 'Provided link is not valid or doesn\'t contain required data',
  'errors.invalidPNRError': 'Invalid personal number provided',
  'errors.noCredsToSaveError': 'Provided credentials are not valid or don\'t contain required data',
  'errors.noPatientError': 'No data found',
  'errors.noPNRError': 'No personal number provided',
  'errors.noUserWithPNR': 'There is no patient with provided personal number',
  'errors.someDataIsNotValidError': 'Some of the provided data is not valid',
  'errors.tokenWasAlreadyAnswered': 'It seems the survey was already answered by this link',
  'errors.unexpectedError': 'An unexpected error occurred',
  'errors.unknownError': 'An unknown error occurred',
  'firstName': 'Your first name',
  'form.error.emailFormat': 'Please check format of email',
  'form.error.mustNotEmpty': 'Field should not be empty',
  'form.error.persNumberFormat': 'Please check length and format of personal number',
  'form.hint.fieldIsOptional': 'Optional',
  'form.hint.mustNotEmpty': 'Required',
  'form.placeholder.select': 'Select your answer...',
  'form.placeholder.typeHere': 'Type here...',
  'lastName': 'Your last name',
  'loader.retrievingRecords': 'Retrieving records and checking data...',
  'notFoundPage.header': 'It seems the page you requested does not exist',
  'notFoundPage.helpMessage': 'Please make sure the link is correct',
  'patient.search.personalNumberHeading': 'Search by patient\'s personal number',
  'patientEmail': 'Patient\'s e-mail (only if Swedish personal number is missing)',
  'patientSearch.hint.emailFormat': 'Provide email with correct format',
  'patientSearch.hint.persNumberFormat': '12 characters',
  'patientSearch.provideEmailOrPnr': 'Please provide person\'s personal number or email',
  'personalNumber': 'Patient\'s personal number',
  'phone': 'Your phone number',
  'relative.relativeForm.heading': 'Please tell us about yourself',
  'relative.relativeForm.headingIfAuth': 'Symptom Survey for',
  'relative.relativeForm.headingIfAuthDescriptionAdditional': 'The person being investigated may due to different reasons experience difficulties in providing a correct and complete description of the condition and symptoms. Therefore, the relatives\' perspective is of great value in the assessment.',
  'relative.relativeForm.headingIfAuthDescriptionMain1': 'You have been asked to complete a Symptom Survey for',
  'relative.relativeForm.headingIfAuthDescriptionMain2': 'as part of a cognitive assessment done through The Memory Clinic app.',
  'relative.relativeForm.headingSearch': 'Please, provide data to start the survey',
  'relative.relativeForm.saveButton': 'Proceed',
  'startPage.button.getStarted': 'Get started',
  'startPage.button.goToSearch': 'Go to search',
  'startPage.heading.linkNotValid': 'It seems the link you provided is not valid',
  'startPage.heading.noQuestionsFromServer': 'An error occurred while fetching data for survey',
  'startPage.heading.noQuestionsTip1': 'Try to refresh the page',
  'startPage.heading.noQuestionsTip2': 'Please check and amend the personal number provided and retry',
  'startPage.heading.searchOption': 'You can also complete the survey with patient\'s personal number',
  'startPage.heading.welcome': 'Welcome to Symptom Survey Page',
  'startPage.welcomeMessage1': 'Please answer questions about',
  'successPage.heading': 'Survey results have been saved successfully!',
  'successPage.thanksMessage': 'Thank you for your answers! The information is of great value for the investigation.',
  'survey.buttons.back': 'Back',
  'survey.buttons.next': 'Next',
  'survey.buttons.save': 'Send results',
  'sv': 'Svenska',
  'symptom-survey.answer.child': 'Child',
  'symptom-survey.answer.difficult-to-discuss-with-patienten': 'Difficult to discuss with patienten',
  'symptom-survey.answer.do-not-know-not-relevant': 'Do not know / not relevant',
  'symptom-survey.answer.dont-know-cant-be-assessed': 'Don\'t know/ can\'t be assessed',
  'symptom-survey.answer.friend': 'Friend',
  'symptom-survey.answer.gradually-transition-in-a-faster-pace': 'Gradually - transition in a faster pace',
  'symptom-survey.answer.gradually-transition-in-an-insidious': 'Gradually - transition in an insidious and slower pace',
  'symptom-survey.answer.half-one-year-ago': '1⁄2 - 1 year ago',
  'symptom-survey.answer.less-than-six-months-ago': 'Less than 6 months ago',
  'symptom-survey.answer.more-than-five-years-ago': 'more than 5 years ago',
  'symptom-survey.answer.no': 'No',
  'symptom-survey.answer.often': 'Often',
  'symptom-survey.answer.one-two-years-ago': '1 - 2 years ago',
  'symptom-survey.answer.other-relationship': 'Other relationship',
  'symptom-survey.answer.parent': 'Parent',
  'symptom-survey.answer.partner': 'Partner',
  'symptom-survey.answer.periodically-returning-symptoms-with-normal-functioning-periods': 'Periodically returning symptoms, with normal functioning periods',
  'symptom-survey.answer.sibling': 'Sibling',
  'symptom-survey.answer.sometimes': 'Sometimes',
  'symptom-survey.answer.somewhat': 'Somewhat',
  'symptom-survey.answer.sudden-change-thereafter-gradual-deterioration': 'Sudden change, - thereafter gradual deterioration',
  'symptom-survey.answer.sudden-change-thereafter-improved': 'Sudden change, - thereafter improved',
  'symptom-survey.answer.sudden-change-thereafter-insidious-deterioration': 'Sudden change, - thereafter insidious deterioration',
  'symptom-survey.answer.sudden-change-thereafter-unchanged': 'Sudden change, - thereafter unchanged',
  'symptom-survey.answer.text': 'Type here...',
  'symptom-survey.answer.three-five-years-ago': '3 - 5 years ago',
  'symptom-survey.answer.two-three-years-ago': '2 - 3 years ago',
  'symptom-survey.answer.yes': 'Yes',
  'symptom-survey.answer.yes-pronounced-often': 'Yes, pronounced / often',
  'symptom-survey.answer.yes-somewhat-sometimes': 'Yes, somewhat / sometimes',
  'symptom-survey.question.assessing-distance': '<strong>assessing distance and get into the correct body position,</strong> for example by sitting on a chair or lying in a bed with the body correctly positioned',
  'symptom-survey.question.become-distrustful': 'has become <strong>distrustful</strong> and may for example make false accusations, show unjustified jealousy or experience a feeling of being stalked. He/she may also express <strong>delusions</strong> in other ways',
  'symptom-survey.question.changed-behavior': '<strong>changed behavior. </strong>This can lead to embarrassing or problematic situations in social life or in cohabitation',
  'symptom-survey.question.changed-eating-habits': '<strong>changed eating habits.</strong> Hunger, satiety or choice of foods is different than before and table manners may be worse',
  'symptom-survey.question.changed-facial-expressions': '<strong>has changed facial expressions.</strong> Facial expressions are less spontaneous and varied, which makes it more difficult to read emotional state and reactions',
  'symptom-survey.question.changed-sense-of-humor': '<strong>changed sense of humor.</strong> Has difficulty understanding jokes, misinterprets, takes things more personally or jokes in a way that is different. Can seem amused and elated with no clear reason',
  'symptom-survey.question.changed-sleeping-habits': '<strong>has changed sleeping habits,</strong> for example sleeping more or sleeping at different time of the day compared to before',
  'symptom-survey.question.changed-walking': '<strong> has changed the way of walking</strong> and the steps are short or clumsier',
  'symptom-survey.question.clumsier-or-trembling': '<strong>is clumsier</strong> or has become more <strong>trembling</strong>',
  'symptom-survey.question.counting': '<strong>counting</strong> and understanding his/her finances, like for an example exchanging money, paying bills, using checks and debit cards, checking bank statements and understanding the value of money',
  'symptom-survey.question.difficulty-remembering-what-was-said': '<strong>had difficulty remembering</strong> what was recently said',
  'symptom-survey.question.evident-specific-event-symptoms': 'For mentioned symptoms in the survey, can you refer to a <strong>specific event or occasion</strong> when these became more evident?',
  'symptom-survey.question.experienced-impaired-awareness': '<strong>experienced impaired awareness,</strong> for example the problems that his/her symptoms lead to or how they impact other people',
  'symptom-survey.question.feeling-more-down': 'is feeling <strong>more down,</strong> gloomy, pondering with loss of "spark" or liveliness',
  'symptom-survey.question.finding-direction': '<strong>finding the right direction</strong> when driving, walking, shopping in department stores or visiting the toilet at acquaintances\' homes',
  'symptom-survey.question.finding-words': '<strong>finding the right words.</strong> Often tries to find another way to explain what he/she means or speaks incoherently',
  'symptom-survey.question.first-noticed-symptoms': '<strong>Which mentioned symptoms</strong> in the survey would you say were <strong>first noticed?</strong>',
  'symptom-survey.question.first-symptoms-noticed': 'Of the mentioned symptoms in the survey, <strong>when were the first symptoms noticed?</strong>',
  'symptom-survey.question.following-instructions-and-manuals': '<strong>following instructions and manuals</strong> with good results, such as when assembling furniture or appliances / tools',
  'symptom-survey.question.hard-to-manage-symptoms': '<strong>Has patienten shared with you if any symptoms have been particularly hard to manage?</strong>',
  'symptom-survey.question.has-been-confused': '<strong>has been confused</strong> at times or periodically. For example, by quickly having an unclear mind, experiencing orientation difficulties of time and space, showing a change in behavior and feeling unsettled',
  'symptom-survey.question.has-hallucinations': '<strong>has hallucinations.</strong> For example, he/she may see, hear or feel things that do not exist in reality, but which he/she clearly felt as an experience',
  'symptom-survey.question.how-related-with-patienten': '<strong>How are you related to or know patienten?</strong>',
  'symptom-survey.question.impaired-balance': '<strong>has impaired balance</strong> and falls over more easily',
  'symptom-survey.question.impaired-judgement': '<strong>impaired judgement.</strong> Can do things that involve risks or must be sorted out and is <strong>less careful,</strong> for example when taking care of the home or personal hygiene',
  'symptom-survey.question.increased-difficulty-getting-involved': '<strong>had increased difficulty getting involved and showing compassion</strong> in what has previously been important, both in terms of the family and society at large. The emotional reactions are weaker, unclear or deviant',
  'symptom-survey.question.increased-difficulty-planning': '<strong>had increased difficulty planning,</strong> for example when it comes to taking care of the home, shopping or choosing suitable clothes',
  'symptom-survey.question.increased-difficulty-remembering-what-day-it-is': 'had increased difficulty remembering <strong>what day it is</strong>',
  'symptom-survey.question.increased-difficulty-remembering-what-has-happened': '<strong>had increased difficulty remembering</strong> what has happened in the past and may have a difficulty remembering the order in which various memorable events occurred',
  'symptom-survey.question.increased-difficulty-remembering-what-time-it-is': 'had increased difficulty remembering <strong>what time of the day it is</strong>',
  'symptom-survey.question.increased-difficulty-remembering-what-to-do': '<strong>had increased difficulty remembering</strong> what to do or will happen, such as making an appointment or remembering a special occasion',
  'symptom-survey.question.increased-difficulty-remembering-what-was-done': '<strong>had increased difficulty remembering</strong> what was done recently',
  'symptom-survey.question.increased-difficulty-remembering-without-being-reminded': '<strong>had increased difficulty remembering</strong> without being reminded or using memory pads, calendar or similar',
  'symptom-survey.question.increased-difficulty-stopping': 'is having increased difficulty stopping when getting angry and can be <strong>threatening</strong> or <strong>violent</strong>',
  'symptom-survey.question.increased-difficulty-taking-initiative': '<strong>had increased difficulty taking initiative.</strong> For example, it is more difficult to take on tasks that have previously been routines or has become overactive in a way that creates problems or a mess',
  'symptom-survey.question.interpreting-maps': '<strong>interpreting maps, drawings or design patterns</strong>',
  'symptom-survey.question.light-hearted': 'is more <strong>light-hearted (tearful / "squeamish")</strong> or <strong>has sudden mood changes</strong> without a clear cause',
  'symptom-survey.question.missed-toilet': '<strong>has a harder time knowing when toilet visits are needed,</strong> which may result in wetting herself/himself',
  'symptom-survey.question.moments-of-absence': 'has short <strong>moments of absence</strong> where he/she does not respond to being addressed or "get stuck" in a movement or activity in a way that is difficult to stop',
  'symptom-survey.question.more-sensitive-to-stress': 'is more <strong>sensitive to stress</strong> and may withdraw from social gatherings or intense activities. Has an increased need to do one thing at a time',
  'symptom-survey.question.more-taciturn': 'become more <strong>taciturn</strong> and <strong>the speech flows less</strong>. Uses <strong>the same words or phrase</strong> over and over again',
  'symptom-survey.question.more-worried': 'is <strong>more worried, restless</strong> and may <strong>feel scared</strong> of certain situations',
  'symptom-survey.question.most-noticeable-symptoms': '<strong>Which mentioned symptoms</strong> in the survey would you say have been <strong>most noticeable?</strong>',
  'symptom-survey.question.moves-slower': '<strong>moves slower</strong>',
  'symptom-survey.question.moves-stiffer': '<strong>moves in a stiffer and more inflexible way</strong>',
  'symptom-survey.question.nuanced-opinions': '<strong>less nuanced opinions,</strong> values and views. Has increased difficulty absorbing arguments and <strong>reasoning illogically</strong>',
  'symptom-survey.question.patienten-agree-with-symptom-survey': '<strong>Does patienten agree with the points you have provided in the symptom survey?</strong>',
  'symptom-survey.question.performing-everyday-tasks': '<strong>performing everyday practical tasks</strong> adequately despite mobility such as dressing, housework, driving a car or using various home appliances',
  'symptom-survey.question.reacts-slowly': '<strong>reacts more slowly.</strong> Finds it difficult to increase pace and do what is needed when something sudden happens',
  'symptom-survey.question.recognizing-objects': '<strong>recognizing</strong> everyday objects, images, symbols or faces of well-known people',
  'symptom-survey.question.space-for-comments': '<strong>Space for comments</strong>',
  'symptom-survey.question.speaks-less': '<strong>speaks less</strong> clearly because the speech has become slurred or because the voice has become weaker',
  'symptom-survey.question.stress-for-the-family': 'Has any of the highlighted symptoms caused any <strong>stress for the family or relatives?</strong>',
  'symptom-survey.question.symptoms-variations-day-to-day': '<strong>Do the symptoms vary from day to day?</strong>',
  'symptom-survey.question.thinks-slower': '<strong>thinks slower</strong> and need to think longer before answering',
  'symptom-survey.question.understanding-heard-read': '<strong>understanding what is heard or read,</strong> for example the content of letters, newspapers or programs on radio / TV',
  'symptom-survey.question.way-symptoms-develop': '<strong>In what way did the symptoms develop?</strong>',
  'symptom-survey.question.writing': '<strong>writing</strong>, for example writing letters, messages or filling out forms',
  'symptom-survey.results.evident-specific-event-symptoms': 'Specific event',
  'symptom-survey.results.first-noticed-symptoms': 'First symptoms',
  'symptom-survey.results.hard-to-manage-symptoms': 'Symptoms difficult to manage',
  'symptom-survey.results.most-noticeable-symptoms': 'Most noticeable symptoms',
  'symptom-survey.results.patienten-agree-with-symptom-survey': 'Patient agrees with description',
  'symptom-survey.results.stress-for-the-family': 'Symptoms stressful for others',
  'symptom-survey.section.mental-reactions-section.subtitle': 'The patient ...',
  'symptom-survey.section.mental-reactions-section.title': 'Common mental reactions and other associated symptoms of diseases affecting the brain',
  'symptom-survey.section.other-questions-section.subtitle': 'The patient ...',
  'symptom-survey.section.other-questions-section.title': 'Other supplementary questions regarding observed symptoms',
  'symptom-survey.section.subcortical-regions-of-brain-section.subtitle': 'The patient ...',
  'symptom-survey.section.subcortical-regions-of-brain-section.title': 'Common symptoms of impaired function in the subcortical regions of the brain',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.subtitle': 'The patient has...',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.title': 'Common symptoms of impaired function in the frontal lobes of the brain',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.subtitle': 'The patient is having a difficulty...',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.title': 'Common symptoms of impaired function in the parietal- and temporal lobes of the brain',
  'symptom-survey.section.symptoms-of-impaired-memory-section.subtitle': 'The patient has...',
  'symptom-survey.section.symptoms-of-impaired-memory-section.title': 'Common symptoms of impaired memory function',
  'thePatient': 'the patient',
  'thePatientCapitalized': 'The patient',
  'word.behaviour': 'behaviour',
  'word.of': 'of',
  'word.pleaseWait': 'Please wait',
  'word.section': 'Section',
  "branding.corp": "Geras Solutions AB, Norrtullsgatan 6,\nStockholm, Sweden.\ninfo@gerassolutions.com",
  "branding.md": "Geras Clinic / Minnesmottagningen",
  "branding.udi": "07350166010010​",
  "button.back": "Back",
  "button.cancel": "Cancel",
  "button.close": "Close",
  "button.confirm": "Confirm",
  "button.retry": "Try again",
  "button.submit": "Accept",
  "error.something_wrong": "Something went wrong. Please try again later.",
  "finish": "Finish",
  "hi": "Hi",
  "loaded": "Loaded",
  "loading": "Loading",
  "next": "Next",
  "no": "No",
  "of": "of",
  "questioner.anamnesis": "Anamnesis",
  "questioner.anamnesis.section.alcohol-drugs-section": "Alcohol & drugs",
  "questioner.anamnesis.section.allgergy-section": "Hypersensitivity & allergy",
  "questioner.anamnesis.section.cognitive-functions-section": "Memory & cognitive functions",
  "questioner.anamnesis.section.concentration-section": "Concentration (ASRS)",
  "questioner.anamnesis.section.depression-section": "Depression (PHQ-9)",
  "questioner.anamnesis.section.dietary-habits-section": "Dietary habits",
  "questioner.anamnesis.section.eq5d-section": "Life quality",
  "questioner.anamnesis.section.exercise-section": "Exercise habits",
  "questioner.anamnesis.section.healthstatus-section": "Health status",
  "questioner.anamnesis.section.hereditary-section": "Heredity",
  "questioner.anamnesis.section.prescriptivedrugs-section": "Pharmaceutical drugs",
  "questioner.anamnesis.section.ptsd-questions-section": "PTSD (PCL-5)",
  "questioner.anamnesis.section.social-background-section": "Social background",
  "questioner.anamnesis.section.stress-questions-section": "Stress (KEDS)",
  "questioner.cognitive": "Cognitive test (GSCT)",
  "questioner.cognitive.section.attention-concentration-working-memory": "Attention, concentration, working memory",
  "questioner.cognitive.section.executive-functioning": "Executive functioning",
  "questioner.cognitive.section.language": "Language",
  "questioner.cognitive.section.memory": "Memory",
  "questioner.cognitive.section.orientation": "Orientation",
  "questioner.cognitive.section.visuospatial-abilities": "Visuospatial abilities",
  "seconds": "seconds",
  "skip": "Skip",
  "versioning.android": "Android app",
  "versioning.hcp": "HCP Portal",
  "versioning.ios": "iOS app",
  "versioning.modal.title": "Application versions",
  "versioning.pp": "Patient Portal",
  "versioning.ss": "Symptom survey",
  "yes": "Yes"
};
 export default trans;
