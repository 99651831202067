import React from 'react';
import {translate} from "../../store/Translations/translations";

const LoaderComponent: React.FC = () => {
    return (
        <div className="pv-40 t_center">
            <p className="h4 pv-10">{translate('word.pleaseWait')}</p>
            <p className="h4 pv-10 t_success">{translate('loader.retrievingRecords')}</p>
        </div>
    );
};

export default LoaderComponent;
