/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ObjectId } from './ObjectId';

export type BankIdAttemptDto = {
    id?: ObjectId;
    referrer: BankIdAttemptDto.referrer;
    personalNumber: string;
    status: BankIdAttemptDto.status;
    userIp: string;
    autoStartToken: string;
    orderRef: string;
};

export namespace BankIdAttemptDto {

    export enum referrer {
        MOBILE_APP = 'mobileApp',
        HCP = 'hcp',
        PATIENT_PORTAL = 'patientPortal',
    }

    export enum status {
        PENDING = 'pending',
        COMPLETE = 'complete',
        FAILED = 'failed',
        ACCOUNT_NOT_FOUND = 'accountNotFound',
    }


}

