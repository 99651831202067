import React from "react";
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import {RouteNames} from "../../types/routes-pathes";
import LoaderComponent from "../../components/Loader";
import PatientFormComponent from "../../components/PatientSearch";

const PatientSearchPage: React.FC<any> = (): React.ReactElement => {
    const isHTTPPending = useSelector((state: any) => state.auth.isFetching);
    const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
    const authenticationFailed = useSelector((state: any) => state.auth.authenticationFailed);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const searchPatientByPersonalNumber = (): void => { };
    return (
        <div className="flex-item--grow">
        { isHTTPPending &&
            <LoaderComponent />
        }
        { !isHTTPPending && isAuthenticated && !authenticationFailed &&
            <Redirect to={RouteNames.RelativeForm}/>
        }
        { !isHTTPPending && !isAuthenticated &&
            <PatientFormComponent
                data={{
                    personalNumber: '',
                }}
                onSubmit={searchPatientByPersonalNumber}>
            </PatientFormComponent>
        }
        </div>
    )
}

export default PatientSearchPage;
