export enum UtilTypes {
    Token = 't',
    Language = 'surveyLanguage'
}

export enum Languages {
    En = 'en',
    Sv = 'sv'
}

export const defaultLanguage = Languages.Sv;

export const defaultPNR = '999999999999';

export const validatorMaxLength = 1000;
