import React from 'react';
import {useLocation, Redirect, RouteComponentProps} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../store/Auth/action";
import { RouteNames } from "../../types/routes-pathes";
import LoaderComponent from "../../components/Loader";
import {UtilTypes} from "../../types/constants";
import {translate} from "../../store/Translations/translations";
import ButtonToSearchPatientComponent from "../../components/StartPageToSearch";
import {isPatientAuthenticated} from "../../store/Auth/authSelectors";
import {isQuestionnaireReady} from "../../store/Survey/surveyStateSelectors";
import {ApplicationState} from "../../store";

const StartPage: React.FC<RouteComponentProps> = (): React.ReactElement => {
    const query = new URLSearchParams(useLocation().search);
    const tokenFromState = useSelector((state: ApplicationState): string | null => state.auth.token);
    const token = query.get(UtilTypes.Token) || tokenFromState || null;
    const dispatch = useDispatch();
    const isHTTPPending = useSelector((state: ApplicationState) => state.app.isHTTPPending);
    const isAuthenticated = useSelector(isPatientAuthenticated);
    const authenticationFailed = useSelector((state: ApplicationState): boolean => state.auth.authenticationFailed);
    const unexpectedError = useSelector((state: ApplicationState): boolean => state.auth.unexpectedError);
    const isQuestionnaireFetched = useSelector(isQuestionnaireReady);
    if (!token) {
        return <Redirect to={RouteNames.RelativeForm}/>;
    }
    if (!isHTTPPending && !isAuthenticated && !authenticationFailed && !unexpectedError) {
        dispatch(loginRequest(token));
    }
    return (
            <div className="flex flex-column align-center justify-center flex-item--grow">
                { isHTTPPending &&
                    <LoaderComponent />
                }
                { !isHTTPPending && isAuthenticated && isQuestionnaireFetched &&
                    <Redirect to={RouteNames.RelativeForm}/>
                }
                { !isHTTPPending && authenticationFailed &&
                    <div className="t_center pv-40 flex-item--grow flex-item--fullwidth">
                        <h1 className="h2">{translate('startPage.heading.linkNotValid')}</h1>
                        <p className="h3 pv-40">{translate('startPage.heading.searchOption')}</p>
                        <ButtonToSearchPatientComponent />
                    </div>
                }
                { !isHTTPPending && unexpectedError &&
                    <div className="t_center pv-40 flex-item--grow flex-item--fullwidth">
                        <h1 className="h2 t_error">{translate('errors.unexpectedError')}</h1>
                        <p className="h3 pt-40">{translate('startPage.heading.noQuestionsTip1')}</p>
                        <p className="h3 pt-20 pb-40">{translate('startPage.heading.noQuestionsTip2')}</p>
                        <ButtonToSearchPatientComponent />
                    </div>
                }
                { !isHTTPPending && isAuthenticated && !isQuestionnaireFetched &&
                    <div className="t_center pv-40 flex-item--grow flex-item--fullwidth">
                        <h1 className="h2 t_error">{translate('startPage.heading.noQuestionsFromServer')}</h1>
                        <p className="h3 pt-40">{translate('startPage.heading.noQuestionsTip1')}</p>
                        <p className="h3 pt-20 pb-40">{translate('startPage.heading.noQuestionsTip2')}</p>
                        <ButtonToSearchPatientComponent />
                    </div>
                }
            </div>
    );
}

export default StartPage;
