import {SurveyActionTypes, SurveyAnswersState, SurveyDataState, SurveyState, SurveyTypes} from "./types";

const surveyState: SurveyState = {
    isQuestionnaireFetched: false,
    errorMsg: '',
    isFailedFetchingData: false,
    isFailedSavingData: false,
    isFetching: false,
    isAnswersSavedSuccessfully: false
};

const surveyDataState: SurveyDataState = {
    sections: [],
    questionerId: ''
};

const answersInitialState: SurveyAnswersState = {};

export function SurveyReducer(
    state = surveyState,
    action: SurveyTypes
): SurveyState {
    switch(action.type) {
        case SurveyActionTypes.GET_SURVEY_DATA:
            return {
                ...state,
                isFetching: true
            };
        case SurveyActionTypes.SET_SURVEY_ERROR_MSG:
            return {
                ...state,
                errorMsg: action.errorMsg
            };
        case SurveyActionTypes.POST_SUCCESS_SURVEY_QUESTIONS:
            return {
                ...state,
                isQuestionnaireFetched: true,
                isFetching: false
            };
        case SurveyActionTypes.POST_FAIL_SURVEY_QUESTIONS:
            return {
                ...state,
                isFetching: false,
                isFailedFetchingData: true,
            };
        case SurveyActionTypes.SAVE_SURVEY_ANSWERS_TO_SERVER:
            return {
                ...state,
                isFetching: true,
                isFailedSavingData: false,
                errorMsg: ''
            };
        case SurveyActionTypes.POST_ANSWERS_TO_SERVER_SUCCESS:
            return {
                ...state,
                isQuestionnaireFetched: false,
                errorMsg: '',
                isFailedFetchingData: false,
                isFailedSavingData: false,
                isFetching: false,
                isAnswersSavedSuccessfully: true
            };
        case SurveyActionTypes.POST_ANSWERS_TO_SERVER_FAIL:
            return {
                ...state,
                isFetching: false,
                isFailedSavingData: true
            };
        default:
            return state;
    }
}

export function SurveyDataReducer(
    state = surveyDataState,
    action: SurveyTypes
): SurveyDataState {
    switch(action.type) {
        case SurveyActionTypes.SET_SURVEY_QUESTIONS:
            return {
                ...state,
                sections: action.data.sections,
                questionerId: action.data.id
            };
        case SurveyActionTypes.CLEAR_QUESTIONS:
            return {
                sections: [],
                questionerId: ''
            };
        default:
            return state;
    }
}

export function SurveyAnswersReducer(
    state = answersInitialState,
    action: SurveyTypes
): SurveyAnswersState {
    switch(action.type) {
        case SurveyActionTypes.ANSWER_REGULAR_QUESTION:
            return {
                ...state,
                [action.payload.id]: action.payload.value
            }
        case SurveyActionTypes.CLEAR_ANSWERS:
            return answersInitialState;
        default:
            return state;
    }
}

