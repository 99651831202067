import React, { SyntheticEvent } from 'react'
import { useDispatch } from "react-redux"
import { answerRegularQuestion } from "../../store/Survey/action"
import { validatorMaxLength } from "../../types/constants"
import './AnswerTextareaComponent.scss'
import { FreeTextInputMetadata, QuestionScreenDTO } from "../../sdk"
import useTranslate from "../../hooks/useTranslate"

const AnswerTextareaComponent: React.FC<any> = ({ screen, answer }: {
    screen: QuestionScreenDTO
    answer: string
}) => {
    const translateLocalazy = useTranslate()
    const dispatch = useDispatch()

    const handleInputChange = (event: SyntheticEvent): void => {
        event.persist()
        const target = event.target as any
        const value = target.value
        if (value.length > validatorMaxLength) {
            return
        }
        dispatch(answerRegularQuestion({
            value,
            id: screen.id!
        }))
    }
    const placeholder = (screen.metadata.input.metadata as FreeTextInputMetadata).placeholder

    return (
        <div className="flex-item p-0">
            <div>
                <textarea
                    name={screen.id}
                    autoComplete="off"
                    value={answer}
                    onChange={handleInputChange}
                    placeholder={translateLocalazy(placeholder)}/>
            </div>
            <p className="t_right t_success t_bold pt-10 h7">{answer?.length || 0} / {validatorMaxLength}</p>
        </div>
    )
}

export default AnswerTextareaComponent
