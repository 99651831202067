import { translate } from "../store/Translations/translations"

export const mapTokenAuthErrorResponse = (statusCode: number): string => {
    let errorMessage = translate('errors.generalAuthTokenError')
    if (statusCode === 400 || statusCode === 401) {
        errorMessage = translate('errors.invalidOrNoToken')
    }
    if (statusCode === 402) {
        errorMessage = translate('errors.tokenWasAlreadyAnswered')
    }
    return errorMessage
}
