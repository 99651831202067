import { GerasApi } from "./GerasApi";

export const apiV2 = new GerasApi({
    BASE: process.env.REACT_APP_NEW_API_URL,
    TOKEN: window.localStorage.getItem('token')!
})

export function attachTokenToRequests() {
    apiV2.request.config.TOKEN = window.localStorage.getItem('token')!
}

export type ApiV2Response<T> = { success: false, error: string, statusCode: number } | {
    success: true,
    data: T,
}

export function wrapResponse<T>(request: Promise<T>): Promise<ApiV2Response<T>> {
    return request
        .then(data => ({ data, success: true } as const))
        .catch(error => {
            if (error?.body?.statusCode === 401) {
                window.localStorage.removeItem('token')
                window.location.replace('/login')
            }

            return ({ error: error.body.message, success: false, statusCode: error?.body?.statusCode } as const)
        })
}

export type SdkResponse<T extends (...args: any) => any> = ApiV2Response<Awaited<ReturnType<T>>>
