import React from "react"
import './SurveySectionHeaderComponent.scss'
import { SectionDTO } from "../../sdk"
import S1Image from "../../assets/img/s1.png"
import S2Image from "../../assets/img/s2.png"
import S3Image from "../../assets/img/s3.png"
import useTranslate from "../../hooks/useTranslate"

const brainImagesNames = {
    'symptoms-of-impaired-function-in-parietal-section': S1Image,
    'symptoms-of-impaired-function-in-frontal-lobes-of-brain': S2Image,
    'subcortical-regions-of-brain-section': S3Image
}

const SurveySectionHeaderComponent = (section: SectionDTO) => {
    const image = brainImagesNames[section.slug]
    const translateLocalazy = useTranslate()

    return (
        <div className="flex flex-item justify-between align-center header-info-wrapper">
            <h2 className="h4 t_italic">{translateLocalazy(`symptom-survey.section.${section.slug}.title`)}</h2>
            {image &&
              <div className="brain-image mb-0">
                <img src={image} alt=""/>
              </div>
            }
        </div>
    )
}

export default SurveySectionHeaderComponent
