import React, { SyntheticEvent } from 'react'
import { useDispatch } from "react-redux"
import { FreeTextInputMetadata, QuestionScreenDTO } from "../../sdk"
import useTranslate from "../../hooks/useTranslate"
import { answerRegularQuestion } from "../../store/Survey/action"

const AnswerInputComponent: React.FC<any> = ({ screen, answer }: {
    screen: QuestionScreenDTO
    answer: string
}) => {
    const translateLocalazy = useTranslate()
    const dispatch = useDispatch()
    const handleInputChange = (event: SyntheticEvent): void => {
        event.persist()
        const target = event.target as any
        const value = target.value
        dispatch(answerRegularQuestion({
            id: screen.id!,
            value
        }))
    }
    const placeholder = (screen.metadata.input.metadata as FreeTextInputMetadata).placeholder
    return (
        <input name={screen.id}
               autoComplete="off"
               type="text"
               value={answer}
               onChange={handleInputChange}
               placeholder={translateLocalazy(placeholder)}/>
    )
}

export default AnswerInputComponent
