import { Middleware } from 'redux';
import {TranslationsActionType, TranslationsActionTypes} from '../store/Translations/types';
import { AuthTypes, AuthActionTypes } from '../store/Auth/types';
import {UtilTypes} from "../types/constants";

const localStorageEnchancer: Middleware = () =>
  (next: (data: any) => any) =>
    (action: TranslationsActionType | AuthTypes): Middleware => {
      switch (action.type) {
        case TranslationsActionTypes.SWITCH_TRANSLATION:
          localStorage.setItem(UtilTypes.Language, action.language);
          break;
        case AuthActionTypes.LOGOUT:
          localStorage.removeItem(UtilTypes.Token);
          break;
      }
      return next(action);
    }

export default localStorageEnchancer;
