import {AuthActionTypes, AuthState, AuthTypes} from './types';
import {defaultPNR} from "../../types/constants";

const initialState: AuthState = {
  isAuthenticated: false,
  authenticationFailed: false,
  patient: null,
  errorMsg: '',
  token: null,
  personalNumber: null,
  email: null,
  unexpectedError: false
}

export function AuthReducer (
  state = initialState,
  action: AuthTypes
): AuthState {
  switch(action.type) {
    case AuthActionTypes.POST_TOKEN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        authenticationFailed: false,
        patient: null,
        token: action.data,
        personalNumber: null,
        email: null,
        unexpectedError: false
      };
    case AuthActionTypes.POST_PATIENT_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        authenticationFailed: false,
        patient: null,
        token: null,
        personalNumber: action.data.personalNumber,
        email: action.data.email,
        unexpectedError: false
      };
    case AuthActionTypes.SEARCH_WITH_DEFAULT_VALUE:
      return {
        ...state,
        isAuthenticated: false,
        authenticationFailed: false,
        patient: null,
        token: null,
        personalNumber: defaultPNR,
        email: null,
        unexpectedError: false
      };
    case AuthActionTypes.POST_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        authenticationFailed: false,
        errorMsg: '',
        unexpectedError: false
      };
    case AuthActionTypes.POST_LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        authenticationFailed: true,
        patient: null,
        token: null,
        personalNumber: null,
        email: null
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        patient: null,
        token: null,
        personalNumber: null,
        email: null
      };      
    case AuthActionTypes.SET_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case AuthActionTypes.SET_UNEXPECTED_ERROR:
      return {
        ...state,
        errorMsg: action.errorMsg,
        unexpectedError: true
      }
    default:
      return state
  }
}
