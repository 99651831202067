import { useState, SyntheticEvent } from 'react';

interface ValidatorInterface {
  [key: string]: (formValue: DefaultFormState) => string | null;
}

const VALIDATOR: ValidatorInterface = {
  email: (formValue) => {
    const value = formValue['email'];
    if (!value) {
      return null;
    }
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.toString()) ? null : 'email'
  },
  firstName: (formValue) => {
    const value = formValue['firstName'];
    if (!value || !value.toString().trim()) {
      return 'firstName';
    }
    return null;
  },
  lastName: (formValue) => {
    const value = formValue['lastName'];
    if (!value || !value.toString().trim()) {
      return 'lastName';
    }
    return null;
  },
  personalNumberCrossEmail: (formValue) => {
    const personalNumber = formValue['personalNumberCrossEmail'] ? formValue['personalNumberCrossEmail'] : null;
    const normalizedPNR = personalNumber ? personalNumber.toString().replace(/-/g, '') : null;
    const email = formValue['emailCrossPersonalNumber'] ? formValue['emailCrossPersonalNumber'].toString().trim() : null;
    if (!normalizedPNR && !email) {
      return 'pnrIsRequired';
    }
    if (!normalizedPNR && email) {
      return null;
    }
    if (normalizedPNR.length !== 12) {
      return 'invalidPnr';
    }
    return null;
  },
  emailCrossPersonalNumber: (formValue) => {
    const personalNumber = formValue['personalNumberCrossEmail'] ? formValue['personalNumberCrossEmail'] : null;
    const normalizedPNR = personalNumber ? personalNumber.toString().replace(/-/g, '') : null;
    const email = formValue['emailCrossPersonalNumber'] ? formValue['emailCrossPersonalNumber'].toString().trim() : null;
    if (!normalizedPNR && !email) {
      return 'emailIsRequired';
    }
    if (!email && normalizedPNR) {
      return null;
    }
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ? null : 'emailIsInvalid';
  }
}

export type DefaultFormState = {
  [key: string]: string | number | [] | string[] | number[] | any;
}

export interface FormStateHandler {
  handleSubmit: (event: SyntheticEvent, extraData?: {[key: string]: any}) => void;
  handleInputChange: (event: SyntheticEvent) => void;
  handleForceUpdate: (data: DefaultFormState, validate?: boolean) => void;
  inputs: DefaultFormState;
  errors: string[];
}

type UnionHtmlCustomElement = HTMLInputElement | HTMLSelectElement;

const useFormStateHandler = <T extends DefaultFormState>(cb: (data: any) => void, initialState: T): FormStateHandler => {
  const [inputs, setInputs] = useState(initialState || {});
  const [errors, setErrors] = useState([] as Array<string | null>);

  const _validate = (props: DefaultFormState): Array<string | null> => Object.keys(props)
    .filter((propKey: string) => VALIDATOR[propKey])
    .map((propKey: string) => VALIDATOR[propKey](props))
    .filter((error: string | null ): string | null => error);

  const handleSubmit = (event?: SyntheticEvent, extraData?: {[key: string]: any}): void => {
    if (event) {
      event.preventDefault();
    }
    const _errors = _validate(inputs);
    // setErrors(() => Object.keys(inputs)
    //   .filter((propKey: string) => _errors.includes(propKey)))

    setErrors(() => _errors);

    if (!_errors.length) {
      cb(extraData ? {...inputs, ...extraData} : inputs);
    }
  }

  const handleForceUpdate = (data: DefaultFormState, validate = true): void => {
    Object.keys(data).forEach((prop: string) => setInputs(inputs => ({ ...inputs, [prop]: data[prop] })));
    if (validate) {
      const _errors = _validate(data);
      setErrors((errors) => Object.keys(inputs)
        .filter((propKey: string) => (Object.keys(data).includes(propKey) && _errors.includes(propKey)) || (!Object.keys(data).includes(propKey) && errors.includes(propKey))))
    }
  }

  const handleInputChange = (event: SyntheticEvent): void => {
    const target = event.target as UnionHtmlCustomElement;
    const _newInputs = { ...inputs, [target.name]: target.value };
    setInputs(_newInputs);
  }

  return {
    handleSubmit,
    handleInputChange,
    handleForceUpdate,
    inputs: (inputs as DefaultFormState),
    errors: errors as string[]
  }
}

export default useFormStateHandler;
