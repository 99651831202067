import { createSelector } from 'reselect';
import {TranslationLanguage} from "./types";
import {ApplicationState} from "../index";
import {defaultLanguage, Languages, UtilTypes} from "../../types/constants";
import store from "../../configureStore";

export const selectTranslations = (state: ApplicationState): Array<TranslationLanguage> => state.translations.languages;

export const selectCurrentLanguage = (state: ApplicationState): Languages => state.translations.currentLanguage;

export const getCurrentLanguage = (state: ApplicationState): string => {
    return state.translations.currentLanguage || localStorage.getItem(UtilTypes.Language) || defaultLanguage;
};

const getTranslation = createSelector(
    selectTranslations,
    (languages: Array<TranslationLanguage>) => (key: string): string => {
        const languageKey = getCurrentLanguage(store.getState());
        const language = languages.find(lang => lang.key === languageKey);
        return (language && language.translations[key]) ? language.translations[key] : key;
    }
);

const getAvailableLanguages = createSelector(
    selectTranslations,
    selectCurrentLanguage,
  (languages: Array<TranslationLanguage>) => languages.map((l: TranslationLanguage) => {
      const languageKey = getCurrentLanguage(store.getState());
      return {
          key: l.key,
          inUse: l.key === languageKey
      };
  })
);

export const getLanguages = getAvailableLanguages;
export const translate = getTranslation(store.getState());
