import React from "react"
import { translate } from "../../store/Translations/translations"
import SurveySectionHeaderComponent from "../SurveySectionHeader"
import SurveyRegularFieldsetComponent from "../SurveyRegularFieldset"

import { useSelector } from "react-redux"
import SurveyHtmlFieldsetComponent from "../SurveyHtmlFieldSet"
import { getCurrentSurveyValidity } from "../../store/Survey/surveyStateSelectors"
import { InputType, QuestionScreenDTO, SectionDTO } from "../../sdk"
import useTranslate from "../../hooks/useTranslate"
import { getPatientName } from "../../store/Auth/authSelectors"

const SurveySectionComponent: React.FC<any> = ({ section, navigateToNext, navigateToPrev }: {
    section: SectionDTO,
    navigateToNext?: () => void,
    navigateToPrev?: () => void
}) => {
    const translateLocalazy = useTranslate()
    const validity = useSelector(getCurrentSurveyValidity)
    const patientName = useSelector(getPatientName)

    return (
        <div className="card-box">
            <div className="pt-10 pb-20">
                <SurveySectionHeaderComponent {...section}/>
            </div>
            <div className="card-box">
                <p className="h2 m-0 t_bold">{translateLocalazy(`symptom-survey.section.${section.slug}.subtitle`)}</p>
                {section.screens.map((item, index) => {
                    const props = {
                        screen: item.screen as QuestionScreenDTO,
                        index: index + 1,
                        patientName
                    }

                    switch ((item.screen as QuestionScreenDTO).metadata.input.kind) {
                        case InputType.RADIO_GROUP:
                            return <SurveyRegularFieldsetComponent key={item.screen.id} {...props} />
                        case InputType.FREE_TEXT:
                        case InputType.TWO_CHOICE:
                        case InputType.CHECKBOX_GROUP:
                        case InputType.SELECTOR:
                            return <SurveyHtmlFieldsetComponent key={item.screen.id} {...props}/>

                    }
                })}
            </div>
            <div className="btns-holder flex-container pt-40 pb-20 ph-40 justify-between">
                {navigateToPrev &&
                  <div className="item--12 item-sm-4 item-md-3 item-lg-2">
                    <button className="t_uppercase g_btn g_btn--outlined g_btn-full-width"
                            onClick={navigateToPrev}
                            type="button">{translate('survey.buttons.back')}</button>
                  </div>
                }
                {navigateToNext && validity[section.slug] &&
                  <div className="flex item--12 item-sm-4 item-md-3 item-lg-2 pt-40 pt-0-sm justify-end ml-auto">
                    <button className="t_uppercase g_btn g_btn--primary g_btn-full-width"
                            onClick={navigateToNext}
                            type="button">{translate('survey.buttons.next')}</button>
                  </div>
                }
                {navigateToNext && !validity[section.slug] &&
                  <div className="flex item--12 item-sm-4 item-md-3 item-lg-2 pt-40 pt-0-sm justify-end ml-auto">
                    <button className="t_uppercase g_btn g_btn--disabled g_btn-full-width"
                            type="button">{translate('survey.buttons.next')}</button>
                  </div>
                }
            </div>
        </div>
    )
}

export default SurveySectionComponent
