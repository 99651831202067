import React, {useEffect} from "react";
import useFormStateHandler, {DefaultFormState} from "../../hooks/formStateHandler";
import {translate} from "../../store/Translations/translations";
import {PatientSearchPayload} from "../../store/Auth/types";

type PatientFormState = PatientSearchPayload;

export type FormState = PatientFormState & DefaultFormState;

type allProps = FormState;

const PatientFormComponent: React.FC<any> = (props: allProps) => {
    const {onSubmit, data} = props;
    const {inputs, errors, handleSubmit, handleInputChange, handleForceUpdate} = useFormStateHandler(onSubmit, data);
    useEffect(() => {
        const _data = Object.keys(data)
            .map((key: string) => data[key as keyof PatientFormState] ? {[key]: data[key as keyof PatientFormState]} : {})
            .reduce((acc, obj) => ({...acc, ...obj}), {})
        handleForceUpdate(_data);
    }, [data]);

    const errorClass = errors.length ? 'field_error' : '';

    return (
        <div className="pv-40 ph-20">
            <h1 className="h3 t_center">{translate('patient.search.personalNumberHeading')}</h1>
            <div className="flex-container">
                <div className="flex-item item-sm-9 item-md-6 item-lg-4 mh-auto pt-40 ph-20">
                    <div className={`input-holder ${errorClass}`}>
                        <label className="pb-10">{translate('personalNumber')}</label>
                        <input value={inputs.personalNumber}
                               name="personalNumber"
                               type="text"
                               onChange={handleInputChange}
                               placeholder={translate('form.placeholder.typeHere')}/>
                        { errors.includes('personalNumber') &&
                            <p className="h7 pt-10 t_error">{translate('form.error.persNumberFormat')}</p>
                        }
                        { !errors.includes('personalNumber') &&
                            <p className="h7 t_right pt-10">{translate('patientSearch.hint.persNumberFormat')}</p>
                        }
                    </div>
                    <div className="btns-holder t_center pt-20">
                        <button onClick={handleSubmit}
                                className="t_uppercase g_btn g_btn--primary"
                                type="button">{translate('search')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PatientFormComponent;
